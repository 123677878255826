import { computed } from 'vue';

export function useBackgroundImage(props) {

  const backgrounds = computed(() => {

    if (!props?.bgImage) {
      return [];
    }

    return [
      `relative`,
      `before:content-['_'] before:bg-[url('${props.bgImage?.replaceAll?.(' ', '_')}')]`,
      `before:block before:absolute`,
      `before:top-0 before:left-0 before:h-full before:w-full`,
      `before:bg-no-repeat before:bg-center before:bg-cover before:z-[0]`,
      `[&>*]:z-[1] [&>*]:relative`,
      (props.bgImageOpacity !== undefined ? `before:opacity-[${props.bgImageOpacity}%]` : ``)
    ];

  });

  return {
    backgrounds,
  };

}
