import { _equal_Q, _clone, _keyword, _keyword_Q } from './types.mjs'
import { _list_Q, LVector, _assoc_BANG, Atom, isMap, isVector } from './types.mjs'
import { pr_str } from './printer.mjs'
// import rl from './node_readline'
// const readline = rl.readline
import { read_str } from './reader.mjs'
// import { readFileSync } from 'fs'

function _error(e) { throw new Error(e) }

// String functions
function slurp(f) {
  // if (typeof process !== 'undefined') {
  //   return readFileSync(f, 'utf-8')
  // } else {
  //   var req = new XMLHttpRequest()
  //   req.open('GET', f, false)
  //   req.send()
  //   if (req.status !== 200) {
  //     _error(`Failed to slurp file: ${f}`)
  //   }
  //   return req.responseText
  // }
}

// Sequence functions
function seq(obj) {
  if (_list_Q(obj)) {
    return obj.length > 0 ? obj : null
  } else if (isVector(obj)) {
    return obj.length > 0 ? Array.from(obj.slice(0)) : null
  } else if (typeof obj === "string" && !_keyword_Q(obj)) {
    return obj.length > 0 ? obj.split('') : null
  } else if (obj === null) {
    return null
  } else {
    _error('seq: called on non-sequence')
  }
}

function toString(v) {
  return typeof v === 'string' ? v : `${v}`
}

// core_ns is namespace of type functions
export const core_ns = new Map([
  ['=', _equal_Q],
  ['throw', a => { throw a }],

  ['nil?', a => a === null],
  ['true?', a => a === true],
  ['false?', a => a === false],
  ['number?', a => typeof a === 'number'],
  ['string?', a => typeof a === "string" && !_keyword_Q(a)],
  ['symbol', a => Symbol.for(a)],
  ['symbol?', a => typeof a === 'symbol'],
  ['keyword', _keyword],
  ['keyword?', _keyword_Q],
  ['fn?', a => typeof a === 'function' && !a.ismacro ],
  ['macro?', a => typeof a === 'function' && !!a.ismacro ],

  ['pr-str', (...a) => a.map(e => pr_str(e,1)).join(' ')],
  ['str', (...a) => a.map(e => pr_str(e,0)).join('')],
  ['prn', (...a) => console.log(...a.map(e => pr_str(e,1))) || null],
  ['println', (...a) => console.log(...a.map(e => pr_str(e,0))) || null],
  ['read-string', read_str],
  // ['readline', readline],
  ['slurp', slurp],

  ['<' , (a,b) => a<b],
  ['<=', (a,b) => a<=b],
  ['>' , (a,b) => a>b],
  ['>=', (a,b) => a>=b],
  ['+' , (a,b) => a+b],
  ['-' , (a,b) => a-b],
  ['*' , (a,b) => a*b],
  ['/' , (a,b) => a/b],
  ["time-ms", () => new Date().getTime()],

  ['list', (...a) => a],
  ['list?', _list_Q],
  ['vector', (...a) => LVector.from(a)],
  ['vector?', a => isVector(a)],
  ['hash-map', (...a) => _assoc_BANG(new Map(), ...a)],
  ['map?', a => isMap(a)],
  ['assoc', (m,...a) => _assoc_BANG(_clone(m), ...a)],
  ['dissoc', (m,...a) => { let n = _clone(m); a.forEach(k => n.delete(k));
    return n}],
  ['get', (m,a) => m === null ? null : m.has(a) ? m.get(a) : null],
  ['contains?', (m,a) => m.has(a)],
  ['keys', a => Array.from(a.keys())],
  ['vals', a => Array.from(a.values())],

  ['sequential?', a => Array.isArray(a)],
  ['cons', (a,b) => [a].concat(b)],
  ['concat', (...a) => a.reduce((x,y) => x.concat(y), [])],
  ['vec', (a) => LVector.from(a)],
  ['nth', (a,b) => b < a.length ? a[b] : _error('nth: index out of range')],
  ['first', a => a !== null && a.length > 0 ? a[0] : null],
  ['rest', a => a === null ? [] : Array.from(a.slice(1))],
  ['empty?', a => a.length === 0],
  ['count', a => a === null ? 0 : a.length],
  ['apply', (f,...a) => f(...a.slice(0, -1).concat(a[a.length-1]))],
  ['map', (f,a) => Array.from(a.map(x => f(x)))],

  ['conj', (s,...a) => _list_Q(s) ? a.reverse().concat(s)
    : LVector.from(s.concat(a))],
  ['seq', seq],

  ['meta', a => 'meta' in a ? a['meta'] : null],
  ['with-meta', (a,b) => { let c = _clone(a); c.meta = b; return c }],
  ['atom', a => new Atom(a)],
  ['atom?', a => a instanceof Atom || a.isAtm],
  ['deref', atm => atm.val],
  ['reset!', (atm,a) => atm.val = a],
  ['swap!', (atm,f,...args) => atm.val = f(...[atm.val].concat(args))],

  // JCL - Added
  ['getp', (m,p) => (Array.isArray(p) ? p : p.split('.')).reduce((a,e) => (a.has(e) ? a.get(e) : null), m)],
  ['getl', (m,p) => (Array.isArray(p) ? p : p.split('.')).reduce((a,e) => (m.has(e) ? a.set(e, m.get(e)) : a), new Map())],
  ['set!', (m,...a) => _assoc_BANG(_clone(m), ...a)],
  ['answer', a => a],
  ['and', (...a) => {
    for (let i = 0; i < a.length; i += 1) {
      if (!a[i]) return false
    }
    return true
  }],
  ['not', (...a) => {
    for (let i = 0; i < a.length; i += 1) {
      if (a[i]) return false
    }
    return true
  }],
  ['or', (...a) => {
    for (let i = 0; i < a.length; i += 1) {
      if (a[i]) return true
    }
    return false
  }],
  ['value', (...a) => {
    for (let i = 0; i < a.length; i += 1) {
      if (a[i]) return a[i]
    }
    return false
  }],
  ['text-join', (s,...a) => a.map(e => `${e}`).join(s)],
  ['timestamp', () => Date.now()],
  ['time-year', () => new Date().getFullYear()],
  ['map-to-map', (mi, mo) => {
    if (!isMap(mi)) _error('Input map expected')
    if (!isMap(mo)) _error('Output map expected')
    let ans = new Map()
    for (let [k, v] of mo) {
      ans.set(v, mi.get(k))
    }
    return ans
  }],
  ['map-keys-upper', m => {
    let ans = new Map()
    for (let [k, v] of m) {
      if (typeof k === 'symbol') ans.set(Symbol.for(Symbol.keyFor(k).toUpperCase()), v)
      else ans.set(k.toUpperCase(), v)
    }
    return ans
  }],
  ['map-keys-lower', m => {
    let ans = new Map()
    for (let [k, v] of m) {
      if (typeof k === 'symbol') ans.set(Symbol.for(Symbol.keyFor(k).toLowerCase()), v)
      else ans.set(k.toLowerCase(), v)
    }
    return ans
  }],
  ['upper', a => { return typeof a === 'string' ? a.toUpperCase() : a }],
  ['lower', a => { return typeof a === 'string' ? a.toLowerCase() : a }],
  ['map-to-where', (md, mf) => {
    let ans = [Symbol.for('where'), []]
    let set = ans[1]
    if (mf.size > 1) {
      set.push(Symbol.for('and'))
    }
    for (let [k, v] of mf) {
      let op = [Symbol.for(v)]
      op.push(typeof k === 'symbol' ? Symbol.keyFor(k) : k)
      op.push(md.get(k))
      set.push(op)
    }
    return ans
  }],
  ['pmt', (rate, nper, pv, fv, type) => {
    console.log('pmt', rate, nper, pv, fv, type)
    let ty = type === 1 ? 1 : 0
    if (rate === 0) return -(pv + fv) / nper
    let pvif = Math.pow(1 + rate, nper)
    let pmt = - rate * (pv * pvif + fv) / (pvif - 1)
    if (ty === 1) pmt /= (1 + rate)
    return pmt
  }],
  ['roundup', (v,d) => {
    if (d === undefined || d === 0) return Math.ceil(v)
    _error('roundup to handle decimal places')
  }],
  ['positive', v => Math.abs(v)],
  ['negative', v => -v],
  ['percent', v => v / 100],
  ['starts-with',   (a,b) => toString(a).startsWith(toString(b))],
  ['ends-with',     (a,b) => toString(a).endsWith(toString(b))],
  ['contains-text', (a,b) => toString(a).indexOf(toString(b)) !== -1],
  ['present', a => {
    console.log('present', typeof a, a, a && a !== '' && a !== null && a !== false && a !== 'false')
    return a && a !== '' && a !== null && a !== false && a !== 'false'
  }],
  ['not-present', a => {
    console.log('not-present', typeof a, a, a === undefined || a === '' || a === null || a === false || a === 'false')
    return a === undefined || a === '' || a === null || a === false || a === 'false'
  }],
])
