<template>
  <label class="inline-flex items-center cursor-pointer" :class="dsbld ? ' cursor-not-allowed opacity-50' : ''">
    <input ref="r" type="checkbox" :id="i" :true-value="v" :value="v" class="sr-only peer" v-model="m" @change="changed" :disabled="dsbld">
    <div :class="a.c(p.cl)" class="after:content-['']"></div>
    <slot></slot>
  </label>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p.id : 'toggle' + p.p?.aid
const v = p.p?.value !== undefined ? p.p.value : true 
const r = ref()
const m = computed(_ => p.modelValue)
const check = _ => r.value.setAttribute('checked', m === v)
const changed = $e => emit('update:modelValue', $e.target.checked ? v : false) 
onMounted(_ => {
  if (m.value !== undefined || v !== undefined) check();
})
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
onUpdated(_ => {
  dsbld.value = isDsbld()
})
</script>