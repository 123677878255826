<template>
  <div :class="a.c(p.cl)" :style="s()">{{v()}}</div>
</template>
<script setup>
import { ref } from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const v = _ => p.p?.pcent ? Math.max(1, p.p?.pcent || 1) + '%' : '' 
const s = _ => 'width: ' + Math.max(1, p.p?.pcent || 100) + '%'
</script>