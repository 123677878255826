import { INJECT, SEVAL } from '../../lib/mal/mal.mjs'
import { dbDatabaseQuery, formsData, pageWidthGte, pageWidthLte, isValidEmail, uuid, dbDatabaseWrite, dbDatabaseRead, serverRunProcess } from './utils'
import {
  isString,
  sym,
  expFind,
  expFindValue,
  toStr,
  isMap,
  expFindValueX,
  isArray,
  isSym,
  sleep,
} from '../../lib/utils'
import {
  userLogin as doUserLogin, userLogout as doUserLogout, userSignUp as doUserSignUp, callServer,
  userVerifyMobile as doUserVerifyMobile, userVerifyEmail as doUserVerifyEmail
} from './security'
import dayjs from 'dayjs'

export { clientInjections }

const log = async function() {
  console.log(...arguments)
  return true
}

function warning(msg) {
  console.warn(msg)
  return false
}

function dbQueryOptions(qry) {
  return [sym('order'), sym('limit'), sym('where'), sym('join')].reduce((acc, el) => {
    let it = expFind(qry, el)
    if (it) acc.push(it)
    return acc
  }, [])
}

async function dbQuery(map) {
  console.log('dbQuery', map)
  let id = map.isMap ? map.get('id') : false
  let qry = map.isMap ? map.get('query') : false
  if (!qry) return warning('No query found');
  let table = expFindValue(qry, sym('table'))
  if (table === undefined) return warning('No table found');
  let options = dbQueryOptions(qry)
  dbDatabaseQuery(this, toStr(table), options)
    .then(res => {
      if (!res) return warning('No query result');
      let it = isString(res) ? this.li(`'${res}`) : res
      if (it && it.isMap) it.set('status', 'Loaded')
      if (!id) return it;
      this.ms(id, it)
    }).catch(err => {
      console.error(err)
  })
  return false
}

function apiOptions(mtd, params) {
  console.log('apiOptions', mtd, params)
  return false
}
async function apiCall(map) {
  console.log('apiCall', map)

  let id = map.isMap ? map.get('id') : false
  let call = map.isMap ? map.get('call') : false
  if (!call) return warning('No call details found');
  let mtd = false
  let dst = expFindValue(call, sym('api-get'))
  if (dst) {
    mtd = 'GET'
  } else {
    dst = expFindValue(call, sym('api-put'))
    if (dst) {
      mtd = 'PUT'
    } else {
      dst = expFindValue(call, sym('api-post'))
      if (dst) {
        mtd = 'POST'
      }
    }
  }
  if (dst === undefined) return warning('No api endpoint found');
  dst = toStr(dst)
  if (this.hasFunc(dst)) { // having a function means this is a call through to the server
    let api = toStr(this.li(`(${dst})`)) // get the real function we are calling.
    api = api.endsWith('.lisp') ? api : `${api}.lisp`
    let params = expFindValueX(call, sym('params')) || []
    console.log('params', params)
    callServer(this, toStr(api), params)
      .then(res => {
        if (!res || !res.data) return warning('No api result');
        let it = isString(res.data) ? this.li(res.data) : res.data
        console.log('it', it)
        if (isMap(it)) it.set('status', 'Loaded');
        if (!id) return it;
        this.ms(id, it)
      }).catch(err => {
        this.error(err.message)
    })
    return false;
  }
  if (!dst.startsWith('http')) dst = `https://${dst}`;
  let params = expFindValue(call, sym('params'))
  let options = apiOptions(mtd, params)
  console.log('api info', mtd, dst, options)
  let data = new Map()
  data.set('ip', '127.0.0.1')
  let it = new Map()
  it.set('data', [data])
  this.ms(id, it)
  return false
}

function demoshare(map) {
  console.log('demoshare', map)
  this.setDemoshare(decodeURIComponent(map.get('tpl')))
  return true
}

function notify(map) {
  console.log('notify', map)
  return true
}

function notifyProgress(map) {
  console.log('notifyProgress', map)
  if (isMap(map) && map.get('correlationId')) {
    let cb = this.getNotify(map.get('correlationId'))
    if (cb) cb(map)
  }
  return true
}

function stillAlive() {
  console.log('stillAlive')
  return true
}

function toggle(key, value) {
  return this.toggle(key, value)
}

function hasSignUps() {
  return this.li('(get config "hasSignUps")')
}

function hasPrivHome() {
  return this.li('(get config "hasPrivateHome")')
}

function hasAdminHome() {
  return this.li('(get config "hasAdminHome")')
}

function hasLoggedInUser() {
  return this.hasUser()
}

function isUserInRole(role) {
  return this.hasUser(role, 0)
}

function isUserInRoleLte(role) {
  return this.hasUser(role, -1)
}

function isUserInRoleGte(role) {
  return this.hasUser(role, 1)
}

function haltEvent(e) {
  if (!e) return;
  e.stopPropagation()
  e.preventDefault()
}

function haltTheEvent() {
  haltEvent(this._event)
}

function userLogin(map) {
  haltEvent(this._event)
  if (!isMap(map) || map.get('Email') === undefined || map.get('Password') === undefined) throw Error('user-login expects a map with Email and Password');
  if (map.get('Email') === '' || map.get('Password') === '') throw Error('user-login expects a map with Email and Password');
  if (!isValidEmail(map.get('Email'))) throw Error('The Email address is invalid');
  doUserLogin(this, map.get('Email').toLowerCase(), map.get('Password'), _ => {
    map.set('showPassword', false)
    map.set('Password', '')
    map.set('Email', '')
    this.ms('Password', '')
    this.ms('Email', '')
  })
}

function userSignUp(map, path) {
  haltEvent(this._event)
  if (!isMap(map) || map.get('Email') === undefined || map.get('Password') === undefined || map.get('ConfirmPassword') === undefined) throw Error('user-signup expects a map with Email, Password and Confirm password');
  if (map.get('Email') === '' || map.get('Password') === '' || map.get('ConfirmPassword') === '') throw Error('user-signup expects a map with Email, Password and Confirm password');
  if (map.get('Password') !== map.get('ConfirmPassword')) throw Error('user-signup Password and Confirm must match');
  if (!isValidEmail(map.get('Email'))) throw Error('The Email address is invalid');
  doUserSignUp(this, map.get('Email').toLowerCase(), map.get('Password'), path, _ => {
    map.set('showPassword', false)
    map.set('Password', '')
    map.set('ConfirmPassword', '')
    this.ms('Password', '')
    this.ms('ConfirmPassword', '')
  })
}

function userLogout() {
  if (!this.hasUser()) return;
  doUserLogout(this, '/')
}

function userVerifyMobile(id, after) {
  if (!id) {
    this.error('user-verify-mobile expects an id')
    return false
  }
  this.ms('userVerifyResult', new Map())
  doUserVerifyMobile(this, id)
    .then(ans => {
      if (!ans) return false;
      if (ans.get('status') !== 'Verified') this.error(`Failed to verify Mobile (${ans.get('status')})`)
      else {
        this.ms('userVerifyResult', ans)
        if (isString(after)) this.nav(after)
        else this.le(after)
      }
    }).catch(err => {
      console.error(err)
  })
  return true
}

function userVerifyEmail(id, after) {
  console.log('userVerifyEmail', id)
  if (!id) {
    this.error('user-verify-mobile expects an id')
    return false
  }
  this.ms('userVerifyResult', new Map())
  doUserVerifyEmail(this, id)
    .then(ans => {
      if (!ans) return false;
      if (ans.get('status') !== 'Verified') this.error(`Failed to verify Email (${ans.get('status')})`)
      else {
        this.ms('userVerifyResult', ans)
        if (isString(after)) this.nav(after)
        else this.le(after)
      }
    }).catch(err => {
      console.error(err)
  })
  return true
}

function userVerifyResult() {
  console.log('userVerifyResult', this.mg('userVerifyResult'))
  return this.mg('userVerifyResult') || new Map()
}

function formData(id) {
  let it = formsData(this._event)
  INJECT('formdata', it, this._env)
  return it
}

function hasError() {
  return this.mg('inerror', false)
}

function clearError() {
  return this.ms('inerror', false)
}

function getErrorMessage() {
  return this.mg('error', 'Oops, something went wrong!')
}

function setErrorMessage(msg) {
  if (msg === undefined || msg === false) this.ms('inerror', false)
  this.error(msg)
}

function getModel(id, dflt) {
  return this.mg(id, dflt)
}

function getPageWidth() {
  return this.mg('breakpoint')
}

function isPageWidthLte(val) {
  return pageWidthLte(val, this.mg('breakpoint'))
}

function isPageWidthGte(val) {
  return pageWidthGte(val, this.mg('breakpoint'))
}

function isLoading() {
  return this.inBrowser() ? this.isLoading() : false
}

function isEmailValid(value) {
  return true
}

function isMobileValid(value) {
  return isString(value) && value !== ''
}

function dateFormat(timestamp, format) {
  if (!timestamp || !format) return 'error: date-format'
  let val = Number(timestamp)
  if (isNaN(val)) val = timestamp
  return dayjs(val).format(format)
}

function appNavigate(val, params) {
  return this.nav(val, params)
}

function getUserApps() {
  // answers the actual server side api to call.
  return 'app-user-apps'
}

function usersEmail() {
  return this.user()?.email || ''
}

function usersUid() {
  return this.user()?.uid || ''
}

function mediaPath(src) {
  return this.a(src)
}

function darkMode() {
  return this.mode('dark')
}

function lightMode() {
  return this.mode('light')
}

function toggleLightDarkMode() {
  return this.mode('tld')
}

function addNotify(apg, map, id, cb) {
  let notify = new Map()
  notify.set('correlationId', id)
  notify.set('sid', apg.wsSid())
  map.set('notify', notify)
  apg.setNotify(id, cb)
}

function userCreateAppNotify(id, map) {
  let track = this.mg(id)
  if (track && track.get('message') === undefined) track.set('message', 'Starting...')
  if (track && track.get('pcent') === undefined) track.set('pcent', 0)
  if (track && track.get('count') !== undefined && track.get('total') === undefined) track.set('total', track.get('count'))
  if (track && track.get('count') !== undefined) track.set('count', track.get('count') - 1)
  if (track && track.get('count') !== undefined) track.set('message', `Generating ${(track.get('total') - track.get('count')) + 1} of ${track.get('total')} artefacts...`)
  let val1 = track.get('total') - track.get('count')
  track.set('pcent', ((val1 / (track.get('total') || 1)) * 100).toFixed(0))
  if (map && map.get('create-app') === 'Ready') {
    track.set('message', 'Application ready')
    this.setNotify(map.get('correlationId'), false)
    let appid = map.get('to')
    if (appid.startsWith('dev-')) appid = appid.replace('dev-', '')
    if (appid.indexOf('.appeggio.com') !== -1) appid = appid.replace('.appeggio.com', '')
    if (appid.indexOf('-com') !== -1) appid = appid.replace('-com', '.com')
    setTimeout(_ => this.nav(`/priv/editapplication?id=${appid}`), 500)
  }
}

function userCreateApp(map) {
  haltEvent(this._event)
  console.log('userCreateApp', map)
  if (!isMap(map)) throw Error('user-create-app expects a map with arguments');
  if (map.get('email') === undefined) map.set('email', this.user()?.email || '')
  if (map.get('uid') === undefined) map.set('uid', this.user()?.uid || '')
  if (map.get('src') === undefined) map.set('src', 'dev-skeleton-com.appeggio.com')
  let id = uuid()
  addNotify(this, map, id, userCreateAppNotify.bind(this, 'appcreate'))
  let it = new Map()
  it.set('status', 'Loading')
  it.set('pcent', 0)
  it.set('done', false)
  this.ms('appcreate', it)
  let error = false
  callServer(this, 'app-create.lisp', map)
    .then(res => {
      if (!res || !res.data) return warning('No api result');
      let it = isString(res.data) ? this.li(res.data) : res.data
      if (isMap(it)) it.set('status', 'Loaded');
      if (!id) return it;
      this.ms('appcreate', it)
    }).catch(err => {
      this.error(err.message)
  })
  this.clearModels((k, v) => (k && k.indexOf && k.indexOf('/priv/applications') !== -1))
  if (!error) this.nav(`/priv/creatingapplication`)
  return true
}

function userCreateTable(map) {
  if (!isMap(map)) throw Error('user-create-table expects a map with arguments');
  if (map.get('name') === undefined) throw Error('user-create-table expects a name');
  let id = uuid()
  addNotify(this, map, id, map.get('progress'))
  map.delete('progress')
  let it = new Map()
  it.set('status', 'Loading')
  it.set('pcent', 1)
  this.ms('appcreatetable', it)
  let error = false
  callServer(this, 'app-create-table.lisp', map)
    .then(res => {
      if (!res || !res.data) return warning('No api result');
      let it = isString(res.data) ? this.li(res.data) : res.data
      if (isMap(it)) it.set('status', 'Loaded');
      if (!id) return it;
      this.ms('appcreatetable', it)
    }).catch(err => {
    this.error(err.message)
  })
  return true
}

function userUpdatePreviewApp(map) {
  if (!isMap(map)) throw Error('user-update-preview-app expects a map with arguments');
  if (map.get('src') === undefined) throw Error('user-update-preview-app expects a src');
  let id = uuid()
  addNotify(this, map, id, map.get('progress'))
  map.delete('progress')
  let it = new Map()
  it.set('status', 'Loading')
  it.set('pcent', 1)
  this.ms('appupdatepreview', it)
  let error = false
  callServer(this, 'app-update-preview.lisp', map)
    .then(res => {
      if (!res || !res.data) return warning('No api result');
      let it = isString(res.data) ? this.li(res.data) : res.data
      if (isMap(it)) it.set('status', 'Loaded');
      if (!id) return it;
      this.ms('appupdatepreview', it)
    }).catch(err => {
      this.error(err.message)
  })
  this.clearModels((k, v) => (k && k.indexOf && k.indexOf('/priv/applications') !== -1))
  return true
}

function appPrepareEdit(id) {
  console.log('appPrepareEdit', id)
  haltEvent(this._event)
  if (!window.location.search) return;
  let appid = window.location.search.substring(window.location.search.indexOf('id=') + 3)
  this.ms(id, new Map().fromObj({ blocked: false, path: '/' }))
  let map = new Map()
  map.set('email', this.user()?.email || '')
  map.set('uid', this.user()?.uid || '')
  map.set('edit', appid)
  callServer(this, 'app-prepare-edit.lisp', map)
    .then(res => {
      if (!res || !res.data) return warning('No api result');
      appid = res.data.replace('.appeggio.', '.lassiappeggio.')
      let p = `/priv/openapplication?app=${appid}`
      let w = window.open(p, '_blank')
      if (w === null) {
        let m = this.mg(id)
        m.set('path', p)
        m.set('blocked', true)
      } else {
        window.focus()
        setTimeout(_ => this.nav('/priv/applications'), 1000)
      }
    }).catch(err => {
      this.error(err.message)
  })
  return true
}

function appOpenEdit() {
  console.log('appOpenEdit')
  haltEvent(this._event)
  if (!window.location.search) return;
  let appid = window.location.search.substring(window.location.search.indexOf('app=') + 4)
  appid = appid.indexOf('.appeggio.') !== -1 ? appid.replace('.appeggio.', '.lassiappeggio.') : `${appid}.lassiappeggio.com`
  window.location.href = `https://${appid}`
  return true
}

function appPreparePreview() {
  console.log('appPreparePreview')
  haltEvent(this._event)
  if (!window.location.search) return;
  let appid = window.location.search.substring(window.location.search.indexOf('id=') + 3)
  appid = appid.indexOf('.appeggio.') !== -1 ? appid.replace('.appeggio.', '.lassiappeggio.') : `${appid}.lassiappeggio.com`
  window.open(`/priv/openapplication?app=${appid}`, '_blank')
  window.focus()
  setTimeout(_ => this.nav('/priv/applications'), 3000)
  return true
}

function appOpenPreview() {
  console.log('appOpenPreview')
  haltEvent(this._event)
  if (!window.location.search) return;
  let appid = window.location.search.substring(window.location.search.indexOf('app=') + 4)
  window.location.href = `https://${appid}`
  return true
}

function showFormData(data, after) {
  console.log('showFromData', data, after, this._event)
  haltEvent(this._event)
  return false
}

async function runServerProcess(name, data) {
  console.log('runServerProcess', name)//, data)
  await sleep(250)
  return await serverRunProcess(this, name, data)
}

function runProcess(name, data, after) {
  console.log('runProcess', name) // , data) //, after, this._event)
  this.ms('inerror', false)
  haltEvent(this._event)
  this.loading()
  try {
    this.loadProc(name)
      .then(proc => {
        if (!isArray(proc)) this.error(`Process '${name}' invalid format`)
        else {
          let id = uuid()
          let top = this.mg(`process-current`)
          this.ms(`process-current`, `process-${id}`)
          this.ms(`process-${id}`, new Map().fromObj({ status: 200, data: 'Starting' }))
          this.le([proc, data, this.mg('config'), id])
            .then(_ => {
              let ans = this.mg(`process-${id}`)
              console.log('run-process - answer', ans)
              if (ans && after) this.le(after).then(_ => {
                if (top) this.ms(`process-current`, top)
              }).catch(err => {
                console.error(err)
                if (top) this.ms(`process-current`, top)
              })
            }).catch(err => {
              console.error(err)
              if (top) this.ms(`process-current`, top)
            })
        }
      }).catch(err => {
        console.error(err)
        this.error(err.message)
      })
  } catch (err) {
    console.warn(err)
  }
  this.loaded()
  return false
}

const Let = sym('let*')
const Input = sym('input')
const State = sym('state')
const Name = sym('name')
const Do = sym('do')

async function stepEval(apg, input, state, name, task) {
  let it = await apg.le([Let, [Input, input, State, state, Name, name], [Do, task]])
  if (isArray(it) && it.length > 0 && isSym(it[0])) return await stepEval(apg, input, state, name, it)
  return it
}

async function processStep(name, input, state, steps) {
  console.log('processStep', name)
  if (!name || !isMap(input) || !isMap(state) || !isMap(steps)) throw new Error('Invalid arguments to process-step!')
  let step = steps.get(name)
  if (!isMap(step)) throw new Error(`Process step '${name}' not found.`)
  let type = step.get('Type')
  if (!type || (type !== 'Choice' && type !== 'Task')) throw new Error(`Process step '${name}' has invalid Type.`)
  let task = step.get('Task')
  if (!task || !isArray(task)) {
    if (type === 'Task' && task === null) return step.get('Next')
    throw new Error(`Process step '${name}' has invalid Task.`)
  }
  let output = await stepEval(this, input, state, name, task)
  if (output) console.log('output', name, output)
  if (type === 'Choice') {
    return output
  } else if (type === 'Task') {
    state.set(name, output)
    return step.get('Next')
  }
  return "End"
}

async function processOutput(id, output) {
  this.ms(`process-${id}`, output)
  return output
}

function processResult() {
  let id = this.mg('process-current')
  // console.log('processResult', id, this.mg(id))
  return id ? this.mg(id, new Map()) : new Map()
}

async function databaseWrite(name, map) {
  return await dbDatabaseWrite(this, [name, [map]])
}

async function databaseReadOne(name, map) {
  console.log('databaseReadOne', name) //, map)
  let it = await dbDatabaseRead(this, [name, [map]])
  if (!isMap(it) || it.get('status') !== 200) {
    console.error('Failed read')
    return it
  }
  let data = ((it.get('data') || new Map()).get('read') || []).filter(el => isArray(el) && el.length > 0 && el[0] === name)
  data = data.length > 0 ? data[0] : []
  data = data.length > 1 ? data[1] : []
  data = data.length > 0 ? data[0] : []
  let ans = new Map()
  ans.set('status', 200)
  ans.set('data', data)
  return ans
}

function sendEmail(map, after) {
  console.log('sendEmail', map, after)
  this.ms('inerror', false)
  callServer(this, 'app-send-email.lisp', map)
    .then(ans => {
      if (ans && ans.status !== 200) this.error(ans.data)
      else {
        console.log('ok', ans)
        if (this.mg('inerror')) return false;
        if (isString(after)) this.nav(after)
        else if (isArray(after)) this.le(after)
      }
    }).catch(err => {
      console.error(err)
  })
  return false
}

const clientInjections = function(apg) {
  INJECT('log', log.bind(apg), apg._env)
  INJECT('database-query', dbQuery.bind(apg), apg._env)
  INJECT('api-call', apiCall.bind(apg), apg._env)
  INJECT('demoshare', demoshare.bind(apg), apg._env)
  INJECT('toggle', toggle.bind(apg), apg._env)
  INJECT('still-alive?', stillAlive.bind(apg), apg._env)
  INJECT('show-form-data', showFormData.bind(apg), apg._env)
  INJECT('notify', notify.bind(apg), apg._env)
  INJECT('notify-progress', notifyProgress.bind(apg), apg._env)
  INJECT('get-user-apps', getUserApps.bind(apg), apg._env)
  INJECT('user-create-app', userCreateApp.bind(apg), apg._env)
  INJECT('user-create-table', userCreateTable.bind(apg), apg._env)
  INJECT('user-update-preview-app', userUpdatePreviewApp.bind(apg), apg._env)
  INJECT('app-prepare-edit', appPrepareEdit.bind(apg), apg._env)
  INJECT('app-open-edit', appOpenEdit.bind(apg), apg._env)
  INJECT('app-prepare-preview', appPreparePreview.bind(apg), apg._env)
  INJECT('app-open-preview', appOpenPreview.bind(apg), apg._env)

  INJECT('dark-mode', darkMode.bind(apg), apg._env)
  INJECT('light-mode', lightMode.bind(apg), apg._env)
  INJECT('toggle-light-dark-mode', toggleLightDarkMode.bind(apg), apg._env)
  INJECT('media-path', mediaPath.bind(apg), apg._env)
  INJECT('date-format', dateFormat.bind(apg), apg._env)
  INJECT('has-error', hasError.bind(apg), apg._env)
  INJECT('clear-error', clearError.bind(apg), apg._env)
  INJECT('get-error-message', getErrorMessage.bind(apg), apg._env)
  INJECT('set-error-message', setErrorMessage.bind(apg), apg._env)
  INJECT('get-model', getModel.bind(apg), apg._env)
  INJECT('halt-event', haltTheEvent.bind(apg), apg._env)
  INJECT('form-data', formData.bind(apg), apg._env)
  INJECT('send-email', sendEmail.bind(apg), apg._env)
  INJECT('users-email', usersEmail.bind(apg), apg._env)
  INJECT('users-uid', usersUid.bind(apg), apg._env)
  INJECT('user-signup', userSignUp.bind(apg), apg._env)
  INJECT('user-login', userLogin.bind(apg), apg._env)
  INJECT('user-logout', userLogout.bind(apg), apg._env)
  INJECT('user-verify-mobile', userVerifyMobile.bind(apg), apg._env)
  INJECT('user-verify-email', userVerifyEmail.bind(apg), apg._env)
  INJECT('get-user-verify-result', userVerifyResult.bind(apg), apg._env)
  INJECT('has-sign-ups', hasSignUps.bind(apg), apg._env)
  INJECT('has-admin-home', hasAdminHome.bind(apg), apg._env)
  INJECT('has-private-home', hasPrivHome.bind(apg), apg._env)
  INJECT('has-logged-in-user', hasLoggedInUser.bind(apg), apg._env)
  INJECT('is-user-in-role', isUserInRole.bind(apg), apg._env)
  INJECT('is-user-in-role-lte', isUserInRoleLte.bind(apg), apg._env)
  INJECT('is-user-in-role-gte', isUserInRoleGte.bind(apg), apg._env)
  INJECT('get-page-width', getPageWidth.bind(apg), apg._env)
  INJECT('is-page-width-lte', isPageWidthLte.bind(apg), apg._env)
  INJECT('is-page-width-gte', isPageWidthGte.bind(apg), apg._env)
  INJECT('is-loading', isLoading.bind(apg), apg._env)
  INJECT('is-valid-email', isEmailValid.bind(apg), apg._env)
  INJECT('is-valid-mobile', isMobileValid.bind(apg), apg._env)
  INJECT('app-navigate', appNavigate.bind(apg), apg._env)
  INJECT('run-process', runProcess.bind(apg), apg._env)
  INJECT('run-server-process', runServerProcess.bind(apg), apg._env)
  INJECT('process-step!', processStep.bind(apg), apg._env)
  INJECT('process-output', processOutput.bind(apg), apg._env)
  INJECT('process-result', processResult.bind(apg), apg._env)
  INJECT('database-write', databaseWrite.bind(apg), apg._env)
  INJECT('database-read-one', databaseReadOne.bind(apg), apg._env)
}
