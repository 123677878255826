<template>
  <ol v-bind="a.p(p.p)" :class="a.c(p.cl)" role="tablist">
    <slot></slot>
  </ol>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const con = v => {
  console.log('step?', v)
}
</script>