<template>
  <ul v-bind="apg.p(p.p)" :class="apg.c(p.cl)">
    <slot name="prepend"></slot>
    <slot></slot>
    <slot name="append"></slot>
  </ul>
</template>
<script setup>
import { ref, onMounted, onUpdated, provide, inject } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits(['update:modelValue'])
const apg = useAppeggio()
</script>