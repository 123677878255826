<template>
  <button ref="el" type="button" v-bind="apg.p(p.p)" :class="apg.c(dcl(p.cl))" :disabled="dsbld" :data-tooltip-target="dtt" :data-tooltip-placement="dtp" :data-dropdown-toggle="ddt" :data-dropdown-offset-distance="dof" data-dropdown-offset-skidding="0" @keydown="keydown">
    <p class="whitespace-nowrap truncate">{{label}}</p><Icon cl="ml-auto justify-end" :p="{ src: 'mdi-chevron-down' }"/>
    <input v-if="p.p?.id" ref="hel" :id="p.p.id" type="hidden" />
  </button>
  <slot></slot>
</template>
<script setup>
import { ref, watch, computed, onMounted, onUpdated, provide } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [ String, Number ] } })
const emit = defineEmits(['update:modelValue'])
const apg = useAppeggio()
const orig = ref(p.modelValue)
const el = ref()
const hel = ref()
const label = ref(p.p?.label || '')
const isDsbld = computed(_ => p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(false)
const dcl = cl => dsbld.value ? cl + ' cursor-not-allowed opacity-50' : cl
const dtt = p.p?.tipid && !dsbld.value ? p.p?.tipid : undefined
const dtp = p.p?.tipplacement ? p.p?.tipplacement : undefined
const ddt = p.p?.id ? p.p.id + 'Dd' : undefined
const dof = ddt && p.p?.ddoffset !== undefined ? p.p.ddoffset : ddt ? '2' : undefined
const m = computed(_ => p.modelValue)
provide('a-get-item', _ => p.modelValue)
provide('a-set-item', v => update(v))
const update = v => {
  if (p.p?.id) hel.value.value = v?.value !== undefined ? v.value : v
  if (p.p?.id) label.value = v?.label !== undefined ? v?.label : p.p?.label || ''
  emit('update:modelValue', v?.value !== undefined ? v.value : v)
}
watch(isDsbld, v => {
  dsbld.value = v
  if (v) {
    label.value = p.p?.label || ''
    emit('update:modelValue', orig.value)
  }
})
watch(m, v => {
  if (p.p?.id && v === '' && hel.value.value !== '') {
    label.value = p.p?.label || ''
    hel.value.value = v 
  }
})
const keydown = $e => {
  if ($e.handled) return;
  if ($e.key === 'Escape') {
    $e.handled = true
    el.value.click()
  }
}
</script>