<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)" role="group">
    <input ref="r" :id="i" type="hidden" data-tmp="true" v-model="m" @change="changed">
    <slot></slot>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p?.id : 'buttonGroup' + p.p?.aid
const r = ref()
const m = computed(_ => p.modelValue)
const changed = _ => {
  emit('update:modelValue', r.value.value)
  // toggleBgDataActive(r.value.parentElement, r.value.value)
}
onMounted(_ => {
  // if (p.modelValue !== undefined) toggleBgDataActive(r.value.parentElement, p.modelValue);
})
</script>