<template>
  <img v-if="s" :src="s" :class="a.c(p.cl)" />
  <div v-else :class="a.c(p.cl)">
    <slot></slot>
  </div>
</template>
<script setup>
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const s = p.p?.src ? p.p.src : false
</script>