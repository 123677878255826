<template>
  <vue-excel-editor v-model="d" v-bind="a.p(p.p)" :class="a.c(p.cl)">
    <template v-if="$slots.default" v-for="(v, i) of $slots.default()">
      <vue-excel-column v-bind="map(v)" />
    </template>
  </vue-excel-editor>
</template>
<script setup>
import {ref, computed, watch} from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const c = computed(_ => a.dModel(p.p.aid))
const d = ref([])
const adj = { src: 'field' }
const map = v => {
  if (!v?.props?.p) return {};
  if (v?.type?.__name !== 'ExcelColumn') {
    console.warn('ExcelTable passed a non-ExcelColumn slot!')
    return {}
  }
  let m = { type: 'string' }
  Object.entries(v.props.p).forEach(([k, v]) => {
    if (adj[k]) m[adj[k]] = v
    else m[k] = v
  })
  return m
}
watch(c, v => {
  if (v && v.isMap && v.get('data')) d.value = a.toObj(v.get('data'));
  if (v && Array.isArray(v)) d.value = v.map(el => a.toObj(el));
}, { immediate: true })
</script>