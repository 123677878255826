<template>
  <li ref="r" v-bind="a.p(m(p.p))" :class="a.c(p.cl)" :data-tabs-target="dtt" role="tab" @click="change">
    <slot></slot>
  </li>
</template>
<script setup>
import { ref, inject, onMounted, watch } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p.id + '-tab' : 'tab' + p.p.aid
const dtt = '#' + (p.p?.id ? p.p.id : p.p.aid)
const m = v => Object.assign({}, v, { id: i })
const r = ref()
const val = inject('a-tabs-value')
const sel = inject('a-tabs-change')
const upd = (v, count) => {
  let el = r.value ? r.value.children[0] : false
  if (!el && count < 8) setTimeout(_ => upd(v, count + 1), 250)
  else if (el) {
    if (v) el.setAttribute('data-active', true)
    else el.removeAttribute('data-active')
    r.value.setAttribute('aria-selected', v)
  }
}
const change = $e => {
  $e.handled = true
  sel(p.p.id)
}
onMounted(_ => {
  if (a.inBrowser() && val.value && p.p?.id) upd(val.value === p.p.id, 0)
})
watch(val, v => {
  if (a.inBrowser() && v && p.p?.id) upd(v === p.p.id, 0)
}, { immediate: true })
</script>