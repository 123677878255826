import Page from './Page.vue'
import ExcelTable from './ExcelTable.vue'
import ExcelColumn from './ExcelColumn.vue'
import Loop from './Loop.vue'
import Dump from './Dump.vue'
import Text from './Text.vue'
import Section from './Section.vue'
import Icon from './Icon.vue'
import Placeholder from './Placeholder.vue'
import Divider from './Divider.vue'
import Accordion from './Accordion.vue'
import AccordionHeader from './AccordionHeader.vue'
import AccordionBody from './AccordionBody.vue'
import Alert from './Alert.vue'
import Avatar from './Avatar.vue'
import Badge from './Badge.vue'
import Banner from './Banner.vue'
import BottomNavigation from './BottomNavigation.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import Button from './Button.vue'
import Link from './Link.vue'
import ButtonGroup from './ButtonGroup.vue'
import Select from './Select.vue'
import Card from './Card.vue'
import CardImage from './CardImage.vue'
import CardBody from './CardBody.vue'
import CardActions from './CardActions.vue'
import Carousel from './Carousel.vue'
import ChatBubble from './ChatBubble.vue'
import Col from './Col.vue'
import Container from './Container.vue'
import DeviceMockup from './DeviceMockup.vue'
import Drawer from './Drawer.vue'
import Collapse from './Collapse.vue'
import CollapseTitle from './CollapseTitle.vue'
import CollapseContent from './CollapseContent.vue'
import Dropdown from './Dropdown.vue'
import Footer from './Footer.vue'
import Form from './Form.vue'
import Gallery from './Gallery.vue'
import Image from './Image.vue'
import Indicator from './Indicator.vue'
import Jumbotron from './Jumbotron.vue'
import Keyboard from './Keyboard.vue'
import ListGroup from './ListGroup.vue'
import List from './List.vue'
import ListItem from './ListItem.vue'
import MegaMenu from './MegaMenu.vue'
import Modal from './Modal.vue'
import Navbar from './Navbar.vue'
import Pagination from './Pagination.vue'
import Popover from './Popover.vue'
import Progress from './Progress.vue'
import Ratings from './Ratings.vue'
import Row from './Row.vue'
import Sidebar from './Sidebar.vue'
import Skeleton from './Skeleton.vue'
import SpeedDial from './SpeedDial.vue'
import Spinner from './Spinner.vue'
import Stepper from './Stepper.vue'
import Step from './Step.vue'
import StepperContent from './StepperContent.vue'
import StepContent from './StepContent.vue'
import Table from './Table.vue'
import TableHeader from './TableHeader.vue'
import TableBody from './TableBody.vue'
import TableRow from './TableRow.vue'
import TableHead from './TableHead.vue'
import TableData from './TableData.vue'
import Tabs from './Tabs.vue'
import TabsHeader from './TabsHeader.vue'
import TabsBody from './TabsBody.vue'
import Tab from './Tab.vue'
import TabContent from './TabContent.vue'
import Timeline from './Timeline.vue'
import Toast from './Toast.vue'
import Tooltip from './Tooltip.vue'
import Article from './Article.vue'
import Video from './Video.vue'
import InputField from './InputField.vue'
import FileInput from './FileInput.vue'
import SearchInput from './SearchInput.vue'
import NumberInput from './NumberInput.vue'
import PhoneInput from './PhoneInput.vue'
import SelectList from './SelectList.vue'
import Textarea from './Textarea.vue'
import Timepicker from './Timepicker.vue'
import Checkbox from './Checkbox.vue'
import Radio from './Radio.vue'
import Toggle from './Toggle.vue'
import RangeInput from './RangeInput.vue'
import FloatingLabelInput from './FloatingLabelInput.vue'
import Label from './Label.vue'
export { register }
function register(app) {
  app.component('Page', Page)
  app.component('ExcelTable', ExcelTable)
  app.component('ExcelColumn', ExcelColumn)
  app.component('Loop', Loop)
  app.component('Dump', Dump)
  app.component('Text', Text)
  app.component('Section', Section)
  app.component('Icon', Icon)
  app.component('Placeholder', Placeholder)
  app.component('Divider', Divider)
  app.component('Accordion', Accordion)
  app.component('AccordionHeader', AccordionHeader)
  app.component('AccordionBody', AccordionBody)
  app.component('Alert', Alert)
  app.component('Avatar', Avatar)
  app.component('Badge', Badge)
  app.component('Banner', Banner)
  app.component('BottomNavigation', BottomNavigation)
  app.component('Breadcrumbs', Breadcrumbs)
  app.component('Button', Button)
  app.component('Link', Link)
  app.component('ButtonGroup', ButtonGroup)
  app.component('Select', Select)
  app.component('Card', Card)
  app.component('CardImage', CardImage)
  app.component('CardBody', CardBody)
  app.component('CardActions', CardActions)
  app.component('Carousel', Carousel)
  app.component('ChatBubble', ChatBubble)
  app.component('Col', Col)
  app.component('Container', Container)
  app.component('DeviceMockup', DeviceMockup)
  app.component('Drawer', Drawer)
  app.component('Collapse', Collapse)
  app.component('CollapseTitle', CollapseTitle)
  app.component('CollapseContent', CollapseContent)
  app.component('Dropdown', Dropdown)
  app.component('Footer', Footer)
  app.component('Form', Form)
  app.component('Gallery', Gallery)
  app.component('Image', Image)
  app.component('Indicator', Indicator)
  app.component('Jumbotron', Jumbotron)
  app.component('Keyboard', Keyboard)
  app.component('ListGroup', ListGroup)
  app.component('List', List)
  app.component('ListItem', ListItem)
  app.component('MegaMenu', MegaMenu)
  app.component('Modal', Modal)
  app.component('Navbar', Navbar)
  app.component('Pagination', Pagination)
  app.component('Popover', Popover)
  app.component('Progress', Progress)
  app.component('Ratings', Ratings)
  app.component('Row', Row)
  app.component('Sidebar', Sidebar)
  app.component('Skeleton', Skeleton)
  app.component('SpeedDial', SpeedDial)
  app.component('Spinner', Spinner)
  app.component('Stepper', Stepper)
  app.component('Step', Step)
  app.component('StepperContent', StepperContent)
  app.component('StepContent', StepContent)
  app.component('Table', Table)
  app.component('TableHeader', TableHeader)
  app.component('TableBody', TableBody)
  app.component('TableRow', TableRow)
  app.component('TableHead', TableHead)
  app.component('TableData', TableData)
  app.component('Tabs', Tabs)
  app.component('TabsHeader', TabsHeader)
  app.component('TabsBody', TabsBody)
  app.component('Tab', Tab)
  app.component('TabContent', TabContent)
  app.component('Timeline', Timeline)
  app.component('Toast', Toast)
  app.component('Tooltip', Tooltip)
  app.component('Article', Article)
  app.component('Video', Video)
  app.component('InputField', InputField)
  app.component('FileInput', FileInput)
  app.component('SearchInput', SearchInput)
  app.component('NumberInput', NumberInput)
  app.component('PhoneInput', PhoneInput)
  app.component('SelectList', SelectList)
  app.component('Textarea', Textarea)
  app.component('Timepicker', Timepicker)
  app.component('Checkbox', Checkbox)
  app.component('Radio', Radio)
  app.component('Toggle', Toggle)
  app.component('RangeInput', RangeInput)
  app.component('FloatingLabelInput', FloatingLabelInput)
  app.component('Label', Label)
}
