<template>
  <li v-bind="a.p(p.p)" :class="a.c(p.cl)" role="presentation">
    <a v-if="p.p.id" @click="s($event)" :class="a.c(p.cl)" style="cursor: pointer;" role="tab">
      <slot></slot>
    </a>
    <slot v-else></slot>  
  </li>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
const s = $e => {
  console.log('selected', $e)
}
</script>