import { inject } from 'vue'

export { useAppeggio }
export { setAppeggio }

const key = Symbol()

function useAppeggio() {
  const appeggio = inject(key)
  return appeggio
}

function setAppeggio(app, appeggio) {
  app.provide(key, appeggio)
}