<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)">
    <slot></slot>
  </div>
</template>
<script setup>
import { computed, watch, onMounted, provide } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const m = computed(_ => p.modelValue)
const c = v => emit('update:modelValue', v)
provide('a-tabs-value', m)
provide('a-tabs-change', c)
onMounted(_ => a.fbInit('tabs'))
</script>