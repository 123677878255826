import { createBrowserListeners } from './listeners'
import { v4 } from 'uuid'
import { clientInjections } from './clientLisp'
import axios from 'axios'
import { PRINT, INJECT } from '../../lib/mal/mal.mjs'
import { getp, isArray, isMap } from '../../lib/utils'
import Cookies from 'js-cookie'
import { Buffer } from 'buffer'
import { callServer } from './security.js'

// TODO.JCL - HACK !
// in /node_modules/flowbite-datepicker/dist/main.cjs.js
// change line 595 to
// var range = null // document.createRange();
// in /node_modules/flowbite-datepicker/dist/main.esm.js
// change line 591 to
// var range = null // document.createRange();

import {
  initAccordions, initCarousels, initCollapses, initDials, initDismisses, initDrawers, initDropdowns, initModals, initPopovers,
  initTabs, initTooltips, initInputCounters,
} from 'flowbite'

export {
  inBrowser, stringify, uuid, toObj, initialize, unload, mounted, getElByAttr, toggle, toMediaPath, query, appInitialize,
  calculateBreakpoint, pageWidthLte, pageWidthGte, formsData, isValidEmail, dmInterp, apicall, getOsInfo, extractTks,
  elSetParentHiddenInput, sendFile, videoFileSign, fileDownload,
  genFromPrompt, gentplFromFileToFile, gentplAll, cfInvalidatePaths, sendDemoshare,
  cmCreateCertificate, cmCertificateValidatedCheck, cfCreateDistribution,
  dbCreateDatabase, dbDatabaseWrite, dbDatabaseDelete, dbDatabaseRead, dbDatabaseQuery, dbDatabaseTables,
  createApp, getApps, makeNavPath, setPageParams, loadProc, serverRunProcess, initFb, winScrollTo,
  setMode,
}

const inBrowser = _ => typeof window !== 'undefined'

const stringify = o => JSON.stringify(o)

const uuid = _ => v4()

function getElByAttr(attr, val) {
  return document.querySelector(`[${attr}='${val}']`)
}

function elFindHiddenInput(el, distance = 0) {
  if (!el) return false;
  for (let item of el.children) {
    if (item.type === 'hidden' && item.getAttribute('data-tmp') === 'true') return item;
  }
  return distance + 1 < 5 ? elFindHiddenInput(el.parentElement, distance + 1) : false
}

function elSetParentHiddenInput($e, val, label) {
  let el = elFindHiddenInput($e.target.parentElement)
  if (el) {
    el.value = val
    if (label) el.setAttribute('label', label);
    el.dispatchEvent(new CustomEvent('change', { detail: { value: val, label: label } }))
  }
}

// function toggleBgDataActive(el, val) {
//   // TODO.JCL - see if this can be done more simply with a selector query?
//   if (!el) return false;
//   let done = false
//   for (let item of el.children) {
//     let ival = item.tagName === 'LI' ? item._value : item.value
//     let it = item.getAttribute('data-active')
//     if (it !== undefined && it !== null) item.removeAttribute('data-active');
//     let cl = item.classList
//     for (let i = 0; i < cl.length && !done; i += 1) {
//       if (cl[i].startsWith('data-[active') && (ival === val || `${ival}` === val)) {
//         item.setAttribute('data-active', true)
//         done = item.getAttribute('label') || true
//       }
//     }
//   }
//   return done
// }
//
// function toggleDdDataActive(el, val) {
//   // TODO.JCL - see if this can be done more simply with a selector query?
//   if (!el) return false;
//   let done = false
//   for (let item of el.children) {
//     let it = toggleBgDataActive(item, val)
//     if (it) return it;
//     if (item.childElementCount > 0) {
//       it = toggleBgDataActive(item, val, true)
//       if (it) return it;
//       for (let child of item.children) {
//         if (child.tagName === 'LI' || child.tagName === 'DIV') {
//           it = toggleBgDataActive(child, val)
//           if (it) {
//             done = it
//           }
//         }
//       }
//     }
//   }
//   return done
// }

function toObj(val) {
  if (isArray(val)) return val.map(el => el.isMap ? el.toObj() : el)
  if (val.isMap) return val.toObj()
  return val
}

function isValidEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

function dmInterp(str, dm) {
  if (!dm || !str) return false;
  let it = String(str)
  let idx = str.indexOf('"~')
  while (idx !== -1) {
    let end = it.indexOf('"', idx + 2)
    let key = it.substring(idx + 2, end)
    let val = getp(dm?.it, key)
    if (val === undefined && (key.endsWith('.Idx') || key.endsWith('.Index') || key === 'Index'  || key === 'Idx')) val = dm.iti;
    if (val === undefined) val = null;
    if (typeof val === 'string') val = `"${val}"`
    it = `${it.substring(0, idx)} ${val} ${it.substring(end + 1)}`
    idx = str.indexOf('"~', end)
  }
  return it
}

function query(apg, store, aid, str) {
  let id = `${aid}-${apg.pageKey()}`
  if (store.model(id) !== undefined) return store.model(id)
  if (store.model(id) === undefined) {
    store.setModel(id, new Map().fromObj({ status: 'Loading' }))
    apg.le(`(database-query { "id" "${id}" "query" '${str} })`)
  }
  return store.model(id)
}

function apicall(apg, store, aid, str) {
  let id = `${aid}-${apg.pageKey()}`
  if (store.model(id) !== undefined) return store.model(id)
  if (store.model(id) === undefined) {
    store.setModel(id, new Map().fromObj({ status: 'Loading' }))
    apg.le(`(api-call { "id" "${id}" "call" '${str} })`)
  }
  return store.model(id)
}

let currentWidth = 0

function winResize($e) {
  if (window.innerWidth !== currentWidth) this.winResize($e);
  currentWidth = window.innerWidth
}

function initialize(apg, webSocket, store, pageContext) {
  let ssrbp = 'xl'
  if (inBrowser()) {
    if (webSocket) webSocket.setApg(apg);
    if (!webSocket) console.log('ws - off');
    currentWidth = window.innerWidth;
    createBrowserListeners(apg, winResize.bind(apg));
    ssrbp = localStorage.getItem('devicebreakpoint')
    let appKey = Cookies.get('mode-id')
    if (appKey) apg.setAppKey(appKey.split('').reverse().join(''))
    let appX = Cookies.get('app-id')
    if (appX) apg.setApiKey(appX.split('').reverse().join(''))
    let editId = Cookies.get('edit-id')
    if (editId) editId = editId.split('').reverse().join('')
    if (editId && window.location.search) {
      let idx = window.location.search.indexOf('_et=')
      if (idx !== -1) {
        let editKey = window.location.search.substring(idx + 4)
        apg.setEdit(editId, editKey)
      }
    }
  } else {
    apg.setAppKey(pageContext.svra)
    apg.setApiKey(pageContext.svrk)
  }
  clientInjections(apg)
  store.model('breakpoint', ssrbp)
}

function unload(apg, webSocket, cb) {
  // Browser unloading
  if (webSocket) webSocket.disconnect();
  if (cb) cb(true);
}

function mounted(apg, webSocket) {
  // Browser mounted
  if (inBrowser() && webSocket) webSocket.connect();
}

function toggle(apg, key, value) {
  switch (key) {
    case 'rtl':
      let html = document.getElementsByTagName('html')[0]
      html.setAttribute('dir', html.getAttribute('dir') === 'ltr' ? 'rtl' : 'ltr')
      break;
  }
}

function toMediaPath(apg, obj) {
  if (obj && obj.startsWith && obj.startsWith('http')) return obj;
  if (obj && obj.startsWith && obj.startsWith('/m/')) obj = obj.replace('/m/', '/mda/');
  if (obj && obj.startsWith && !obj.startsWith('/mda/')) obj = `/mda/${obj}`;
  return `${apg.host()}/${apg.appId()}${obj}`
}

function genFromPrompt(apg, prompt, temperature, topp) {
  console.log('genFromPrompt')
  return new Promise((res, rej) => {
    let exp = `{ "temperature" ${temperature || 0.5} "prompt" "${encodeURIComponent(prompt)}" "topp" ${topp ? topp : false} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/generate-template-from-prompt.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        res(apg.li(r.data))
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function gentplFromFileToFile(apg, src, dst) {
  console.log('gentplFromFileToFile -', src, dst)
  return new Promise((res, rej) => {
    let exp = `{ "src" "${src}" "dst" "${dst}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/generate-template-from-file-to-file.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('gentplFromFileToFile ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function sendDemoshare(apg, tpl) {
  console.log('sendDemoshare')
  return new Promise((res, rej) => {
    let exp = `{ "tpl" "${encodeURIComponent(tpl)}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/demo-share.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('demoshare ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function cfInvalidatePaths(apg, root, paths) {
  console.log('cfInvalidatePaths', root, paths)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "1234" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"paths" '(${paths.join(' ')}) }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`https://api.lassiappeggio.com/${root}/func/cloudfront-invalidate-paths.lisp`, l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('cfInvalidatePaths ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function cmCreateCertificate(apg, domain) {
  console.log('cmCreateCertificate', domain)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "5678" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"domain" "${domain}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/certificate-manager-create.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('cmCreateCertificate ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function cmCertificateValidatedCheck(apg, domain) {
  console.log('cmCertificateValidatedCheck', domain)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "9012" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"domain" "${domain}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/certificate-manager-validation-check.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('cmCertificateValidatedCheck ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function cfCreateDistribution(apg, domain) {
  console.log('cfCreateDistribution', domain)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "3456" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"domain" "${domain}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/cloudfront-create-distribution.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('cfCreateDistribution ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function dbCreateDatabase(apg, name) {
  console.log('dbCreateDatabase', name)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "1212" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"name" "${name}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/database-create.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('dbCreateDatabase ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function dbDatabaseWrite(apg, data) {
  console.log('dbDatabaseWrite', data)
  apg.loading()
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "${uuid()}" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"write" '${PRINT(data)} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/func/database-write.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        apg.loaded()
        console.log('dbDatabaseWrite ans', r.data)
        console.log('dbDatabaseWrite exp', `{ "status" ${r.status} "data" ${r.data} }`)
        res(apg.li(`{ "status" ${r.status} "data" ${r.data} }`))
      } catch (e) {
        apg.loaded()
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function dbDatabaseDelete(apg, data) {
  console.log('dbDatabaseDelete', data)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "1212" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"delete" '${PRINT(data)} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/database-delete.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('dbDatabaseDelete ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function dbDatabaseRead(apg, data) {
  console.log('dbDatabaseRead', data)
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "${uuid()}" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"read" '${PRINT(data)} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/func/database-read.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('dbDatabaseRead ans', r.data)
        res(apg.li(`{ "status" ${r.status} "data" ${r.data} }`))
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function dbDatabaseTables(apg) {
  console.log('dbDatabaseTables')
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "4343" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/database-tables.lisp', l, {
      headers: {
        'x-api-key': '01tpmdizu36Uj0l0S4C4V6XkVboRjzol7mFwJq1z1QDhr1ZLCP0avpunoqZNAJ',
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('dbDatabaseTables ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function dbDatabaseQuery(apg, name, options) {
  console.log('dbDatabaseQuery', name)
  apg.loading()
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "${uuid()}" "sid" "${apg.wsSid()}" } ` : ''
    let opt = options ? ` "options" '${PRINT(options)} ` : ''
    let exp = `{${notify}"name" "${name}"${opt}}`
    let l = `(fn* (input) (eval \`(~input ${exp})))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/func/database-query.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('dbDatabaseQuery ans', r.data)
        apg.loaded()
        res(r.data)
      } catch (e) {
        apg.loaded()
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function serverRunProcess(apg, name, data) {
  apg.loading()
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "${uuid()}" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}"name" "${name}" "data" '${PRINT(data)} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/func/server-run-process.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        apg.loaded()
        console.log('serverRunProcess ans', r.status, r.data)
        res(apg.li(`{ "status" ${r.status} "data" ${r.data} }`))
      } catch (e) {
        apg.loaded()
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function sendEmail(apg, data) {
  console.log('sendEmail', data)
  apg.loading()
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "${uuid()}" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify} "data" '${PRINT(data)} }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/func/sendserver-run-process.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        apg.loaded()
        console.log('serverRunProcess ans', r.status, r.data)
        res(apg.li(`{ "status" ${r.status} "data" ${r.data} }`))
      } catch (e) {
        apg.loaded()
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function getApps(apg, email, uid) {
  console.log('getApps', email, uid)
  apg.loading()
  return new Promise((res, rej) => {
    let exp = `{ "email" "${email}" "uid" "${uid}" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/app-user-apps.lisp', l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      console.log('getApps ans', apg.li(r.data))
      apg.loaded()
      res(apg.li(r.data))
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function createApp(apg) {
  console.log('createApp')
  apg.loading()
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "3333" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify} "src" "lassiappeggio-com.appeggio.com" "dst" "example.com" "email" "james@appeggio.com" "uid" "a1f46344-9e8e-4f8f-a11c-dcfd93d6f34b" }`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post('https://api.lassiappeggio.com/lassiappeggio-com.appeggio.com/func/app-create.lisp', l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('createApp ans', r.data)
        apg.loaded()
        res(true)
      } catch (e) {
        apg.loaded()
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      apg.loaded()
      console.error(e)
      rej(false)
    })
  })
}

function gentplAll(apg, root) {
  console.log('genTplAll')
  return new Promise((res, rej) => {
    let notify = apg.isWsOn() ? ` "notify" { "correlationId" "4321" "sid" "${apg.wsSid()}" } ` : ''
    let exp = `{${notify}}`
    let l = `(fn* (input) (eval \`(~input ${exp} )))`
    axios.post(`${apg.apiRoot()}/${root}/func/generate-template-all.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        console.log('gentplAll ans', r.data)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function loadConfig(apg, store) {
  return new Promise((res, rej) => {
    let l = `(fn* (input) (answer input))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/cfg/config.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        store.setModel('config', apg.li(r.data))
        INJECT('config', store.model('config'), apg._env)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function loadThemes(apg, store) {
  return new Promise((res, rej) => {
    let l = `(fn* (input) (answer input))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/cfg/themes.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        store.setModel('themes', apg.li(r.data))
        INJECT('themes', store.model('themes'), apg._env)
        res(true)
      } catch (e) {
        console.error(e)
        rej(false)
      }
    }).catch(e => {
      console.error(e)
      rej(false)
    })
  })
}

function loadProc(apg, store, name) {
  console.log('loadProc', name)
  let proc = store.proc(name)
  if (proc) return Promise.resolve(proc)
  return new Promise((res, rej) => {
    let l = `(fn* (input) (answer input))`
    axios.post(`${apg.apiRoot()}/${apg.appId()}/proc/${name}.lisp`, l, {
      headers: {
        'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
        'Accept': 'text/plain',
        'Content-Type': 'text/plain'
      }
    }).then(r => {
      try {
        store.setProc(name, apg.li(`'${r.data}`))
        res(store.proc(name))
      } catch (err) {
        rej(err)
      }
    }).catch(e => rej(e))
  })
}

function loadPageParams(apg, pageContext) {
  return new Promise((res, rej) => {
    setPageParams(apg, pageContext)
    res(true)
  })
}

async function appInitialize(apg, store, pageContext) {
  let tasks = []
  tasks.push(loadConfig(apg, store))
  if (!apg.inBrowser()) tasks.push(loadThemes(apg, store));
  tasks.push(loadPageParams(apg, pageContext))
  await Promise.all(tasks)
}

function calculateBreakpoint(apg, store) {
  if (apg.inBrowser()) {
    let w = (document.getElementsByTagName('body')[0]).clientWidth
    console.log('calc bp', w)

    let bp = false
    if (w >= 1536) bp = '2xl'
    else if (w >= 1280) bp = 'xl'
    else if (w >= 1024) bp = 'lg'
    else if (w >= 768) bp = 'md'
    else if (w >= 640) bp = 'sm'
    else bp = 'xs'

    if (store.model('breakpoint') !== bp) {
      store.setModel('breakpoint', bp)
      // sessionStorage.setItem('devicebreakpoint', bp)
      localStorage.setItem('devicebreakpoint', bp)
    }
    console.log('breakpoint', store.model('breakpoint'))
  }
}

function pageWidthLte(a, b) {
  if (a === b) return true;
  switch (a) {
    case 'xs':
      return b === 'xs'
    case 'sm':
      return b === 'xs'
    case 'md':
      return b === 'xs' || b === 'sm'
    case 'lg':
      return b === 'xs' || b === 'sm' || b === 'md'
    case 'xl':
      return b === 'xs' || b === 'sm' || b === 'md' || b === 'lg'
    case '2xl':
      return b === 'xs' || b === 'sm' || b === 'md' || b === 'lg' || b === 'xl'
    default:
      return false
  }
}

function pageWidthGte(a, b) {
  if (a === b) return true;
  switch (a) {
    case 'xs':
      return b === 'sm' || b === 'md' || b === 'lg' || b === 'xl' || b === '2xl'
    case 'sm':
      return b === 'md' || b === 'lg' || b === 'xl' || b === '2xl'
    case 'md':
      return b === 'lg' || b === 'xl' || b === '2xl'
    case 'lg':
      return b === 'xl' || b === '2xl'
    case 'xl':
      return b === '2xl'
    case '2xl':
      return b === '2xl'
    default:
      return false
  }
}

function formsData(form) {
  if (!form || form.target === undefined) return new Map();
  let data = new Map()
  let els = form.target.elements
  for (let i = 0; i < els.length; i += 1) {
    // console.log('form item', els[i].type, els[i].id, els[i].name, els[i].value, els[i].checked)
    let key = els[i].id || els[i].name
    let val = els[i].value
    if (key !== '') {
      if (els[i].type !== 'radio' && els[i].type !== 'checkbox') data.set(key, val)
      else {
        if (els[i].checked) data.set(key, val)
        else if (!data.has(key)) data.set(key, false)
      }
    }
  }
  return data
}

function getOsInfo() {
  if (typeof window === 'undefined') return {};
  let userOs = navigator.platform || ''
  let os = userOs.toLowerCase()
  let apple = os.includes('mac') || os.includes('ios')
  let mobile = os.includes('ios') || os.includes('android')
  let android = os.includes('android')
  return { os, apple, android, mobile }
}

function makeNavPath(inBrowser, path, params) {
  if (!params || !isMap(params)) return path
  let set = []
  for (let [k, v] of params) {
    set.push(`${k}=${v}`)
  }
  let it = `${path}?${set.join('&')}`
  return inBrowser ? encodeURI(it) : it
}

function setPageParams(apg, pageContext) {
  let url = pageContext?.urlParsed ? pageContext.urlParsed : {}
  let search = url?.search ? url.search : {}
  let keys = Object.keys(search)
  let ans = keys.length > 0 ? new Map().fromObj(search) : new Map()
  INJECT('pageparams', ans, apg._env)
  apg.ms('pageparams', ans)
  // console.log('pageparams', ans)
}

function extractTks(tpl, key) {
  if (!tpl || !key) return false
  let it = `'${key}':'`
  let idx = tpl.indexOf(it)
  if (idx === -1) return false
  let end = tpl.indexOf("'", idx + it.length)
  if (end === -1) return false
  return tpl.substring(idx + it.length, end)
}

function sendFile(apg, type, url, data) {
  return new Promise((res, rej) => {
    let headers = {
      'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
      'Accept': 'text/plain',
      'Content-Type': type
    }
    let buffer = data.replace ? Buffer.from(data.replace(/^data:image\/\w+;base64,/, ''), 'base64') : Buffer.from(data, 'base64')
    let options = { headers, timeout: 60000 }
    let args = [url, buffer, options]
    axios['put'](...args)
      .then(ur => {
        buffer = false
        res(ur)
      }).catch(err => {
      buffer = false
      rej(err)
    })
  })
}

function videoFileSign(apg, file) {
  let map = new Map()
  map.set('appid', apg.appId())
  map.set('name', file)
  return new Promise((res, rej) => {
    callServer(apg, 'app-video-signed.lisp', map)
      .then(ans => {
        res(ans && ans.status === 200 ? apg.li(`'${ans.data}`) : false)
      }).catch(err => {
      console.warn(err)
      rej(err)
    })
  })
}

function fileDownload(apg, file) {
  let map = new Map()
  map.set('appid', apg.appId())
  map.set('name', file)
  return new Promise((res, rej) => {
    callServer(apg, 'app-download-signed.lisp', map)
      .then(ans => {
        let it = ans && ans.status === 200 ? apg.li(`'${ans.data}`) : false
        if (it) it = it.get('url')
        if (it) {
          let link = document.createElement('a')
          link.href = it
          link.setAttribute('download', file)
          document.body.appendChild(link)
          link.click()
          setTimeout(_ => document.body.removeChild(link), 200)
        }
        res(true)
      }).catch(err => {
        console.warn(err)
        rej(err)
    })
  })
}

function initFb(comp) {
  console.log('initFb', comp)
  if (!inBrowser()) return;
  switch (comp) {
    case 'accordions':
      initAccordions()
      break;
    case 'carousels':
      initCarousels()
     break;
    case 'collapses':
      initCollapses()
      break;
    case 'dials':
      initDials()
      break;
    case 'dismisses':
      initDismisses()
      break;
    case 'drawers':
      initDrawers()
      break;
    case 'dropdowns':
      initDropdowns()
      break;
    case 'modals':
      initModals()
      break;
    case 'popovers':
      initPopovers()
      break;
    case 'tabs':
      initTabs()
      break;
    case 'tooltips':
      initTooltips()
      break;
    case 'inputCounters':
      initInputCounters()
      break;
    // case 'copyClipboards':
    //   initCopyClipboards()
    //   break;
    default:
      console.warn('Init of unknown component', comp)
  }
}

function adjForNav() {
  let els = document.getElementsByTagName('NAV')
  if (!els || els.length === 0) return 0;
  let el = els[0]
  if (!el.classList.contains('fixed')) return 0;
  let rect = el.getBoundingClientRect()
  return rect.height
}

function winScrollTo(apg, id) {
  let el = document.getElementById(id)
  if (!el) el = document.querySelectorAll(`[data-anchor*="${id}"]`)
  if (el && el.length > 0) {
    let yOffset = adjForNav() + 1
    let y = (el[0].getBoundingClientRect().top + window.scrollY) - yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

function setMode(apg, id) {
  if (!apg.inBrowser()) return;
  let el = document.documentElement
  if (!el) return;
  if (id === 'dark' || id === 'light') {
      el.classList.remove('dark')
      el.classList.remove('light')
      el.classList.add(id === 'dark' ? 'dark' : 'light')
  } else if (id === 'tld') {
    let add = el.classList.contains('dark') ? 'light' : 'dark'
    let rem = el.classList.contains('dark') ? 'dark' : 'light'
    el.classList.remove(rem)
    el.classList.add(add)
  }
}
