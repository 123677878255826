export { meta }

// Shades - note 500 is the key shade
// 50 100 200 300 400 500 600 700 800 900
// bg-??-900   = background-color:
// text-??-900 = color:
// border-??-900 = color:
// ring-??-900 = color:

// https://uicolors.app/create

// https://m3.material.io/components/all-buttons

function colors(pal) {
  let set = []
  let variants = [ 'primary', 'secondary', 'accent' ]
  let shades = [ '50', '100', '200', '300', '400', '500', '600', '700', '800', '900' ]
  variants.map(color => {
    shades.map(shade => {
      set.push(`.bg-${color}-${shade}, .hover\\:bg-${color}-${shade}:hover, .focus\\:bg-${color}-${shade}:focus, .active\\:bg-${color}-${shade}:active { background-color: ${pal[color][shade]} }`)
      set.push(`.text-${color}-${shade}, .hover\\:text-${color}-${shade}:hover, .focus\\:text-${color}-${shade}:focus, .active\\:text-${color}-${shade}:active { color: ${pal[color][shade]} }`)
      set.push(`.border-${color}-${shade}, .hover\\:border-${color}-${shade}:hover, .focus\\:border-${color}-${shade}:focus, .active\\:border-${color}-${shade}:active { border-color: ${pal[color][shade]} }`)
      set.push(`.ring-${color}-${shade}, .hover\\:ring-${color}-${shade}:hover, .focus\\:ring-${color}-${shade}:focus, .active\\:ring-${color}-${shade}:active { box-shadow: #ffffff 0 0 0 2px, ${pal[color][shade]} 0 0 0 4px, rgba(0,0,0,0) 0 0 0 0 !important }`)
      set.push(`.data-\\[active\\=true\\]\\:bg-${color}-${shade}[data-active=true] { background-color: ${pal[color][shade]} }`)
      set.push(`.data-\\[active\\=true\\]\\:text-${color}-${shade}[data-active=true] { color: ${pal[color][shade]} }`)
      set.push(`.data-\\[active\\=true\\]\\:border-${color}-${shade}[data-active=true] { border-color: ${pal[color][shade]} }`)
      set.push(`.data-\\[active\\=true\\]\\:ring-${color}-${shade}[data-active=true] { box-shadow: #ffffff 0 0 0 2px, ${pal[color][shade]} 0 0 0 4px, rgba(0,0,0,0) 0 0 0 0 !important }`)
      set.push(`.ring-offset-${color}-${shade}, .hover\\:ring-offset-${color}-${shade}:hover, .focus\\:ring-offset-${color}-${shade}:focus, .active\\:ring-offset-${color}-${shade}:active { --tw-ring-offset-color: ${pal[color][shade]} !important; }`)
      set.push(`.shadow-${color}-${shade}, .hover\\:shadow-${color}-${shade}:hover, .focus\\:shadow-${color}-${shade}:focus, .active\\:shadow-${color}-${shade}:active { --tw-shadow-color: ${pal[color][shade]} !important; --tw-shadow: var(--tw-shadow-colored); }`)
    })
  })
  return set.join('\n')
}

async function pageTitleMeta(apg, cfg, tpl, reader) {
  let extracted = reader ? await reader(apg.appId(), apg.locale(), tpl) : {}
  console.log('pageTitleMeta', extracted)
  let titleTpl = cfg['TitleTemplate'] || '{title}'
  let title = extracted.title || ''
  let description = extracted.description || cfg['Description'] || ''
  let shortDescription = cfg['ShortDescription'] || ''
  let keywords = extracted.keywords || cfg['Keywords'] || ''
  return { title: titleTpl.replace('{title}', title), description, keywords, shortDescription }
}

async function meta(apg, pageContext, reader) {
  let obj = {}

  let cfg = (apg.mg('config')).toObj()
  let thm = (apg.mg('themes')).toObj()

  let { title, description, keywords, shortDescription } = await pageTitleMeta(apg, cfg, pageContext?.tpl, reader)
  obj.title = title
  obj.shortTitle = cfg.Name
  obj.description = description
  obj.shortDescription = shortDescription
  obj.keywords = keywords
  obj.appName = cfg.Name
  obj.imageSrc = apg.a('/mda/' + cfg['previewImage'])

  obj.fontStyles = `
.primary-font { font-family: ${thm.fontFamilies.primary} !important; }
.secondary-font { font-family: ${thm.fontFamilies.secondary} !important; }
.accent-font { font-family: ${thm.fontFamilies.accent} !important; }
.neutral-font { font-family: ${thm.fontFamilies.neutral} !important; }`

  obj.otherStyles = colors(thm.palette)

  obj.fontFamilies = [ ... new Set(Object.values(thm.fontFamilies).flatMap(it => it)) ]
  obj.fontsSpecifier = obj.fontFamilies.map(it => `${it}:100,200,300,400,500,600,700,800,100italic,200italic,300italic,400italic,500italic,600italic,700italic,800italic`)
  obj.webfontLoadScript = `WebFont.load({ google: { families: ${JSON.stringify(obj.fontsSpecifier)} } })`

  obj.webIcon = apg.a('/mda/' + thm.icons?.['favicon-32x32'] || '')
  obj.iconsLinks = `
    <link rel='apple-touch-icon' sizes='180x180' href='${apg.a('/mda/' + thm.icons['appleTouch'])}'>
    <link rel='icon' type='image/png' sizes='32x32' href='${apg.a('/mda/' + thm.icons['favicon-32x32'])}'>
    <link rel='icon' type='image/png' sizes='16x16' href='${apg.a('/mda/' + thm.icons['favicon-16x16'])}'>
  `

  let url = apg.pagePath() === '/' ? apg.host() : `${apg.host()}${apg.pagePath()}`
  obj.ogTags = `
    <link rel="canonical" href="${url}">
    <link rel="image_src" href="${obj.imageSrc}">
    <meta property="og:url" content="${url}">
    <meta property="og:type" content="website">
    <meta property="og:title" content="${obj.title || obj.shortTitle}">
    <meta property="og:twitter:title" content="${obj.title || obj.shortTitle}">
    <meta property="og:site_name" content="${obj.appName}">
    <meta property="og:twitter:site_name" content="${obj.appName}">
    <meta property="twitter:card" content="${obj.imageSrc}">
    <meta property="og:description" content="${obj.description}">
    <meta property="twitter:description" content="${obj.description}">
    <meta property="og:image" content="${obj.imageSrc}">
    <meta property="twitter:image:src" content="${obj.imageSrc}">
  `;


  obj.googleAnalyticsId = cfg['GoogleAnalyticsId'] || ''
  obj.googleSiteIdTag = cfg['GoogleSiteId'] || ''
  if (obj.googleSiteIdTag !== '') obj.googleSiteIdTag = `<meta name="google-site-verification" content="${obj.googleSiteIdTag}" />`

  if (!apg.inBrowser()) {
    if (process.env.APP_STAGE !== 'prod' || apg.appId().startsWith('dev-') || apg.appId().startsWith('pview-')) {
      console.log('**** REMOVING some meta tags as we are not in Dev/dev-/pview- ****')
      obj.googleAnalyticsId = ''
      obj.googleSiteIdTag = ''
    }
  }
  return obj
}
