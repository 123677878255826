<template>
  <li ref="el" v-bind="apg.p(p.p)" :class="apg.c(elcl(p.cl || ''))" @click="click">
    <template v-if="$slots.default || $slots.prepend || $slots.append">
      <slot name="prepend"></slot>
      <slot></slot>
      <slot name="append"></slot>
    </template>  
    <template v-else>
      <span>{{p.p?.label}}</span>  
    </template>  
  </li>
</template>
<script setup>
import { ref, onMounted, onUpdated, inject, watch } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const apg = useAppeggio()
const el = ref()
const elcl = cl => p.p?.value !== undefined ? cl + ' cursor-pointer' : cl
const elmg = inject('a-get-item', false)
const elms = inject('a-set-item', false)
const click = $e => {
  if (elms) elms({ value: p.p?.value, label: p.p?.label })
  else console.warn('ListItem with no setter', p.p?.value, p.p?.label)
}
const updateValue = (set, val) => {
  let elv = val !== undefined ? val : elmg ? elmg() : undefined
  if (p.p.value !== elv) el.value.removeAttribute('data-active');
  if (p.p.value === elv) {
    el.value.setAttribute('data-active', true)
    if (set) {
      if (elms) elms({ value: p.p?.value, label: p.p?.label })
      else console.warn('ListItem with no setter', p.p?.value, p.p?.label)
    }
  }
}
const updator = p.p?.value !== undefined && p.p?.label !== undefined ? updateValue : _ => (false)
onUpdated(_ => updator())
onMounted(_ => updator(true, undefined))
if (elmg) watch(elmg, v => updator(undefined, v));
</script>