import { inBrowser, stringify } from './utils'
import { isString } from '../../lib/utils'

export { needWebSocket, createWebSocket }

function needWebSocket(enabled) {
  return inBrowser() && enabled === true
}

function createWebSocket(sid, appId, con, stage, region) {
  return webSocket(sid, appId, `wss://${con}.execute-api.${region}.amazonaws.com/${stage}`)
}

function webSocket(sid, appId, url) {

  let apg = false
  let socket = false
  let open = false

  return {
    setApg(inst) {
      apg = inst
    },
    loading() {
      if (!open) this.connect()
    },
    connect() {
      console.log('ws - connect', sid)
      let s = socket = new WebSocket(url)
      s.onclose = ({ wasClean, code, reason }) => this.closed(code)
      s.onerror = e => this.error(e)
      s.onmessage = m => this.message(m)
      s.onopen = _ => this.opened()
    },
    disconnect() {
      this.sendBg({ cmd: 'closing' })
      socket.close()
    },
    send(route, msg, tries = 0) {
      try {
        socket.send(stringify({ action: route, data: { sid, appId, msg } }))
      } catch (err) {
        console.log('is open', open)
        if (open && tries + 1 < 4) setTimeout(_ => this.send(route, msg, tries + 1), 250)
        else console.error(err.message)
      }
    },
    sendFg(data) {
      this.send('fg', data)
    },
    sendBg(data) {
      this.send('bg', data)
    },
    push(value) {
      if (apg) apg.wsmsg(value);
    },
    opened() {
      open = true
      setTimeout(_ => this.sendBg({ cmd: 'opened' }), 250)
    },
    closed(code) {
      if (code === 1001) {
        console.log('** Server closed the connection **')
        this.push('Server closed the connection.');
      }
      open = false
    },
    message(msg) {
      if (!msg) return;
      if (url.startsWith(msg?.origin)) {
        try {
          this.push(isString(msg.data) ? JSON.parse(msg.data) : msg.data)
        } catch (err) {
          console.warn(err)
          console.warn('ws - received invalid data', msg.data)
        }
      } else {
        console.warn(`message from unknown origin '${msg?.origin}'`)
      }
    },
    error(err) {
      console.warn('ws - error', err)
    },
  }
}
