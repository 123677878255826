import { defineStore } from 'pinia'
import { objEntries } from '../../lib/utils'
import { getOsInfo } from './utils'

const ClearExclusions = { 'config': true, 'themes': true, }

function clearExcluding(k, v) {
  console.log('store - clearExcluding', k, ClearExclusions[k] === undefined)
  return ClearExclusions[k] === undefined
}

let resizing = false
let resizingIgnored = false

export const useApgStore = defineStore('apgStore', {
  state: () => ({
    demotpl: false,
    models: {},
    loads: 0,
    inload: false,
    pgver: '',
    pgresize: 1,
    notify: {},
    ddchange: `${Date.now()}`,
    procs: {},
    resizing: false,
  }),
  actions: {
    setDemoshare(demotpl) {
      console.log('store - demoshare')
      this.demotpl = demotpl
    },
    getDemoshare() {
      return this.demotpl
    },
    setModel(id, val) {
      this.models[id] = val
    },
    model(id) {
      return this.models[id]
    },
    clearModels(cb = clearExcluding) {
      objEntries(this.models).forEach(([k, v]) => {
        if (cb(k, v)) {
          delete this.models[k]
        }
      })
    },
    isLoading() {
      return this.inload
    },
    loading() {
      this.loads += 1
      this.inload = true
    },
    loaded() {
      this.loads -= 1
      if (this.loads <= 0) {
        this.inload = false
        this.loads = 0
      }
    },
    pageVersion(apg) {
      if (this.pgver !== apg.pageKey()) this.pgver = apg.pageKey();
      return this.pgver
    },
    setNotify(id, cb) {
      this.notify[id] = cb
    },
    getNotify(id) {
      return this.notify[id] || false
    },
    osInfo() {
      if (this.models['osinfo'] === false) {
        this.models['osinfo'] = getOsInfo()
      }
      return this.models['osinfo']
    },
    ddChange() {
      return this.ddchange
    },
    setDdChange(id) {
      this.ddchange = `${id}${Date.now()}`
    },
    proc(name) {
      return this.procs[name]
    },
    setProc(name, proc) {
      this.procs[name] = proc
    },
    pageResized() {
      return this.pgresize
    },
    resized($e) {
      if (resizing) {
        resizingIgnored = true
        return;
      }
      resizing = setTimeout(_ => {
        if (resizingIgnored) {
          console.log('apg - ** resized **')
          this.pgresize += 1
        }
        resizingIgnored = false
        clearTimeout(resizing)
        resizing = false
      }, 250)
      console.log('apg - ** resized **')
      this.pgresize += 1
    }
  }
})
