import { AComp } from './AComp.js'
import APageShown from './APageShown.vue'
import APageLoading from './APageLoading.vue'
import ALoop from './ALoop.vue'
import AVideo from './AVideo.vue'
import Tester from './Tester.vue'
import ShowIt from './ShowIt.vue'
import EDatabase from './EDatabase.vue'

import { register as registerOthers } from './components/generated/register.js'

export { register }

function register(app) {
  app.component('a-comp', AComp)
  app.component('a-page-shown', APageShown)
  app.component('a-page-loading', APageLoading)
  app.component('a-loop', ALoop)
  app.component('a-video', AVideo)
  app.component('Tester', Tester)
  app.component('EDatabase', EDatabase)
  app.component('Show-it', ShowIt)
  registerOthers(app)
}
