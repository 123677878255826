<template>
  <ul v-bind="a.p(m(p.p))" :class="a.c(p.cl)" :data-tabs-toggle="dtt">
    <slot></slot>
  </ul>
</template>
<script setup>
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p.id + '-tab' : 'tabsHeader' + p.p.aid
const dtt = i + '-content'
const m = v => Object.assign({}, v, { id: i })
</script>