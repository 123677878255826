<template>
  <div class="relative max-w-full">
    <img v-bind="a.p(p.p)" :class="a.c(p.cl)" :src="a.a(p.p.src)"/>
    <div v-if="$slots.default" class="absolute left-0 top-0 w-full h-full overflow-y-auto">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
</script>