<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)" :data-accordion="d">
    <slot></slot>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const d = p.p?.opt === 'open' ? 'open' : 'collapse'
onMounted(_ => a.fbInit('accordions'))
</script>