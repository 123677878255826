<template>
  <div ref="r" v-bind="a.p(p.p)" :class="[ backgrounds, a.c(p.cl) ]" class="a-page" :style="s">
    <slot name="header"></slot>
    <div v-if="$slots.error"><slot name="error"></slot></div>
    <div class="a-page-layout flex" :class="{ 'h-0 !grow': a.isDashLayout() }">
      <template v-if="$slots.start"><slot name="start" class="a-page-left"></slot></template>
      <div class="a-page-content w-0 grow" :class="{ 'h-full overflow-y-scroll': a.isDashLayout() }">
        <slot name="content"></slot>
        <slot></slot>
      </div>
      <template v-if="$slots.end"><slot name="end" class="a-page-left"></slot></template>  
    </div>
    <slot name="footer"></slot>
    <a-page-shown v-on:pgshn="em($event)"/>
    <a-page-loading />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useBackgroundImage } from '../../composables/use-background-image.js'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['page-shown'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const r = ref()
const s = ref(false)
const { backgrounds } = useBackgroundImage(p.p)
const em = $e => {
  a.emit($e, v => emit('page-shown', v))
  adjForNav()
}
const adjForNav = _ => {
  let els = r.value.getElementsByTagName('NAV')
  if (!els || els.length === 0) return;
  let el = els[0]
  if (!el.classList.contains('fixed')) return;
  if (backgrounds && backgrounds.value !== undefined && backgrounds.value.length > 0) return;
  let rect = el.getBoundingClientRect()
  s.value = 'padding-top:' + rect.height + 'px'
}
onMounted(_ => adjForNav())
</script>