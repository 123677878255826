<template>
  <vue-excel-editor ref="grid" v-model="data"
                    @update="onUpdate" @delete="onDelete" @select="onSelect" @cell-click="onCellClick"
                    @cell-focus="onCellFocus" @page-changed="onPageChanged" @setting="onSetting" @style="onStyle"
                    @register="onRegister" :remember="getName" :no-finding="true" :no-finding-next="true"
                    :disable-panel-setting="true" :no-header-edit="true" :disable-panel-filter="true" :new-if-bottom="true">
    <template v-for="(col, idx) of schema" :key="idx">
      <vue-excel-column v-bind="col" />
    </template>
  </vue-excel-editor>
</template>

<script setup>
import { watch, ref, computed, onMounted } from 'vue'
import { useAppeggio } from './useAppeggio.js'

const p = defineProps({ opts: { type: Object }, cb: { type: Function } })
const emit = defineEmits(['update:modelValue'])

const apg = useAppeggio()
const db = computed(_ => p.opts)
const data = ref([])
const schema = ref([])
const grid = ref()

const getName = _ => {
  return db.value.name
}

const onUpdate = v => {
  if (p.cb) p.cb('update', v)
}

const onRegister = (f, p) => {
  if (p.cb) p.cb('register', f, p)
}

const onDelete = v => {
  if (p.cb) p.cb('delete', v)
}

const onSelect = (selectId, direction) => {
  if (p.cb) p.cb('select', selectId, direction)
}

const onCellClick = (r, c) => {
  if (p.cb) p.cb('cellClick', r, c)
}

const onCellFocus = (rowPos, colPos, cell, rec) => {
  if (p.cb) p.cb('cellFocus', rowPos, colPos, cell, rec)
}

const onPageChanged = (pageTopPos, pageBottomPos) => {
  if (p.cb) p.cb('pageChanged', pageTopPos, pageBottomPos)
}

const onSetting = setting => {
  if (p.cb) p.cb('setting', setting)
}

const onStyle = style => {
  console.log('EDatabase - onStyle', style)
}

onMounted(_ => {
  if (p.cb) p.cb('grid', grid.value)
})

watch(db, v => {
  data.value = v ? v?.data : undefined
  schema.value = v ? v?.schema : undefined
}, { immediate: true, deep: true })
</script>
