<template>
  <slot name="prepend"></slot>
  <template v-for="(item, idx) in d">
    <slot :dm="{ it: item, iti: idx, itc: d.length }"></slot>
  </template>
  <slot name="append"></slot>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useAppeggio } from './useAppeggio.js'

const a = useAppeggio()
const p = defineProps({ dm: { type: Object }, aid: { type: Number } })
const c = computed(_ => a.dModel(p.aid))
const d = ref([])

watch(c, v => {
  if (v && v.isMap && v.get('data')) d.value = v.get('data');
  if (v && Array.isArray(v)) d.value = v;
}, { immediate: true })

</script>
