<template>
  <h1 class="my-10">Show all</h1>
  <a-comp v-if="tpl" v-bind='{ apg, tpl }' />
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useAppeggio } from './useAppeggio.js'

const apg = useAppeggio()

const demo = computed(() => apg.getDemoshare())
const tpl = ref(false)

watch(demo, v => {
  console.log('watch', v)
  if (v) tpl.value = v
}, { immediate: true })
</script>
