<template>
  <div id="page-container">
    <slot />
  </div>
</template>

<script setup>
import './css/index.css'

import { computed, onMounted, watch } from 'vue'
import { useAppeggio } from '../app/useAppeggio.js'

const apg = useAppeggio()
const updated = computed(() => apg.pageVersion())

onMounted(_ => apg.mounted())

const onPageShown = v => {
  if (!apg.inBrowser()) return;
  console.log('pageshown', v, window.location.hash)
  if (window.location.hash !== '') apg.scrollTo(window.location.hash)
}

watch(updated, v => {
  if (!apg.inBrowser()) return;
  setTimeout(() => onPageShown(v), 100)
}, { immediate: true })
</script>
