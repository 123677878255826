import { objEntries, isArray, isObj, unStrQuote } from './utils'

export { applyPatches, _toObj }

const _toObj = (map) => {
  if (!(map instanceof Map)) return map
  let obj = {}
  for (let [key, value] of map) {
    if (value === null || value === undefined) obj[key] = null
    else if (Array.isArray(value)) obj[key] = value.map(el => _toObj(el))
    else if (value instanceof Map || typeof value === 'object') obj[key] = _toObj(value)
    else obj[key] = unStrQuote(value)
  }
  return obj
}

const patchMap = _ => {
  Map.prototype.isMap = function() { return true; }
  Map.prototype._fromArray = function(a) {
    if (!isArray(a)) return a;
    return a.reduce((acc, val) => {
      if (isArray(val)) acc.push(this._fromArray(val))
      else if (val.isMap) acc.push(val)
      else if (isObj(val)) acc.push(this.fromObj(val))
      else acc.push(val)
      return acc
    }, [])
  }
  Map.prototype.fromObj = function(o) {
    return objEntries(o).reduce((acc, [key, val]) => {
      if (val === undefined) return acc;
      if (val === null) acc.set(key, null)
      else if (isArray(val)) acc.set(key, this._fromArray(val))
      else if (val.isMap) acc.set(key, val)
      else if (isObj(val)) acc.set(key, this.fromObj(val))
      else acc.set(key, val)
      return acc
    }, new Map())
  }
  Map.prototype.toObj = function() {
    return _toObj(this)
  }
}

const applyPatches = () => patchMap()
