export {
  mapFrom, objFrom, objAssign, objEntries, isObj, sym, symk, qte, isMap, kwd, noKwd, isKwd, isArray, isString, isNumber, isFunc, isSym,
  isEmptyMap, stringify, isAppStageLocal, sleep, trim, noLSlash, noRSlash, asyncReduce, batch, unStrQuote, startsUpperCase,
  expFind, expFindValue, expFindValueX, toNumber, toStr, getp, expFindDeep
}

const stringify = o => isString(o) ? o : JSON.stringify(o)

const isObj = o => typeof o === 'object'

const objEntries = o => isObj(o) ? Object.entries(o) : {}

const objFrom = m => {
  if (!m instanceof Map) throw new Error('Map expected!')
  return Object.fromEntries(m)
}

const mapFrom = o => {
  if (typeof o !== 'object') throw new Error('Object expected!')
  return new Map(objEntries(o))
}

const objAssign = function() {
  let o = {}
  for (let i = 0; i < arguments.length; i += 1) {
    o = Object.assign(o, arguments[i])
  }
  return o
}

const sym = s => Symbol.for(s)

const symk = s => isSym(s) ? Symbol.keyFor(s) : s

const qte = a => [ sym('quote'), a]

const isMap = m => m && (m instanceof Map || m.isMap !== undefined)

const isString = s => typeof s === 'string'

const kwd = s => `\u029e${s}`

const noKwd = s => s && s.startsWith && s.startsWith('\u029e') ? s.substring(1) : s

const isKwd = s => s && s.startsWith && s.startsWith('\u029e')

const isArray = a => Array.isArray(a)

const isNumber = n => typeof n === 'number'

const isFunc = f => typeof f === 'function'

const isSym = s => typeof s === 'symbol'

const isEmptyMap = m => isMap(m) && m.size === 0

const isAppStageLocal = _ => process.env.APP_STAGE === 'local'

const sleep = time => new Promise(r => setTimeout(r, time))

const trim = s => s && s.trim ? s.trim() : s

const noLSlash = s => s && s.startsWith && s.startsWith('/') ? s.substring(1) : s

const noRSlash = s => s && s.endsWith && s.endsWith('/') ? s.substring(0, s.length - 1) : s

const unStrQuote = s => isString(s) && s.startsWith('"') && s.endsWith('"') ? s.substring(1, s.length - 1) : s

const startsUpperCase = s => isString(s) ? s.substring(0, 1) === s.substring(0, 1).toUpperCase() : false

const toNumber = s => isNumber(s) ? s : Number(s)

const toStr = s => isString(s) ? s : isSym(s) ? symk(s) : s !== undefined ? `${s}` : s

const asyncReduce = async function(arr, acc, fn) {
  if (!isArray(arr)) return acc;
  let tmp = acc
  for (let idx = 0; idx < arr.length; idx += 1) {
    tmp = await fn(tmp, arr[idx], idx)
  }
  return tmp
}

const batchTasks = function(set, fn) {
  return new Promise((res, rej) => {
    let next = idx => {
      if (idx < set.length) {
        fn(set[idx])
          .then(_ => next(idx + 1))
          .catch(e => {
            console.warn(`Error: batchTasks ${e.toString()}`)
            next(idx + 1)
          })
      } else {
        res(true)
      }
    }
    next(0)
  })
}

const batch = async function(set, fn, size = 2) {
  let tasks = []
  let task = []
  for (let i = 0; i < set.length; i += 1) {
    if (task.length + 1 > size) {
      tasks.push(batchTasks(task, fn))
      task = []
    }
    task.push(set[i])
  }
  if (task.length > 0) {
    tasks.push(batchTasks(task, fn))
  }
  return tasks.length > 0 ? await Promise.all(tasks) : Promise.resolve(true)
}

function expFindDeep(list, obj) {
  if (!isArray(list) || list.length === 0) return undefined;
  if (list[0] === obj) return list;
  for (let i = 0; i < list.length; i += 1) {
    if (isArray(list[i]) && list[i].length > 0 && list[i][0] === obj) return list[i];
    let it = isArray(list[i]) ? expFindDeep(list[i], obj) : false
    if (it) return it;
  }
  return undefined
}

function expFind(list, obj) {
  if (!isArray(list) || list.length === 0) return undefined;
  if (list[0] === obj) return list;
  for (let i = 0; i < list.length; i += 1) {
    if (isArray(list[i]) && list[i].length > 0 && list[i][0] === obj) return list[i];
  }
  return undefined
}

function expFindValue(list, obj) {
  let it = expFind(list, obj)
  if (it === undefined) return it;
  if (!isArray(it)) return undefined;
  if (it.length > 1 && it[0] === obj) return it[1]
  return it
}

function expFindValueX(list, obj) {
  let it = expFind(list, obj)
  if (it === undefined) return it;
  if (!isArray(it)) return undefined;
  if (it.length > 1 && it[0] === obj) return it.slice(1)
  return it
}

const getp = function(map, path) {
  if (!isMap(map)) return null;
  if (!path) return null;
  return map.get(path)
}
