<template>
  <section v-bind="a.p(p.p)" :class="[ backgrounds, a.c(p.cl) ]" class="w-full" :data-anchor="p.p?.anchor ? '#' + p.p.anchor : undefined">
    <div class="max-w-screen-2xl mx-auto" :class="i">
      <slot></slot>
    </div>
  </section>
</template>
<script setup>
import { useBackgroundImage } from '../../composables/use-background-image.js'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const { backgrounds } = useBackgroundImage(p.p)
const i = p.p?.indent ? 'px-' + p.p?.indent : undefined
</script>