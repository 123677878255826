<template>
  <input type="file" v-bind="a.p(p.p)" :class="a.c(p.cl)">
    <slot></slot>
  </input>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
</script>
