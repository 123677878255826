<template>
  <div v-bind="a.p(p.p)" :class="[ backgrounds, a.c(p.cl) ]" :data-anchor="p.p?.anchor ? '#' + p.p.anchor : undefined">
    <slot></slot>
  </div>
</template>
<script setup>
import { useBackgroundImage } from '../../composables/use-background-image.js'
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const { backgrounds } = useBackgroundImage(p.p)
</script>