import axios from 'axios'
import { navigate } from 'vike/client/router'
import { clearPages } from '../renderer/+onRenderClient'
import { PRINT } from '../../lib/mal/mal.mjs'
import { isMap } from '../../lib/utils'

export { userLogin, userLogout, userSignUp, getSession, roleIndex, isRole, inRole, roleGte, roleLte, callServer, userVerifyEmail, userVerifyMobile }

const Roles = [
  { title: 'RoleAnonymous', value: 'visitor' },
  { title: 'RoleAuthenticated', value: 'user' },
  { title: 'RoleAuthenticatedAdmin', value: 'admin' },
  { title: 'RoleAuthenticatedSuperAdmin', value: 'superadmin' },
  { title: 'RoleAuthenticatedEditorAdmin', value: 'editoradmin' },
  { title: 'RoleAuthenticatedOwnerAdmin', value: 'owneradmin' },
]

const inRole = (a, dir, b) => {
  if (a === undefined || b === undefined) return false;
  if (dir === undefined || dir === 0) return isRole(b, a)
  if (dir === -1) return roleLte(b, a)
  if (dir === 1) return roleGte(b, a)
}

const isRole = (a, b) => {
  if (!a || !b) return false;
  return roleIndex(a) === roleIndex(b)
}

const roleGte = (a, b) => {
  if (!a || !b) return false;
  return roleIndex(a) >= roleIndex(b)
}

const roleLte = (a, b) => {
  if (!a || !b) return false;
  return roleIndex(a) <= roleIndex(b)
}

const roleIndex = input => {
  let idx = 0
  for (let i = 0; i < Roles.length; i += 1) {
    let role = Roles[i]
    if (role.value === input) return idx;
    idx += 1
  }
  return -1
}

const jsonHeaders = _ => {
  return { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Pragma': 'no-cache', 'Cache-Control': 'no-cache', 'cache-control': 'no-cache' }
}

const loadCsrf = async function(apg) {
  let opts = { headers: getHeaders(apg, jsonHeaders()) }
  let args = [`${apg.host()}/api/auth/csrf`, opts]
  return new Promise((res, rej) => {
    send(apg, 'get', args, ans => {
      res(ans?.data?.csrfToken)
    }, err => {
      console.warn(err)
      res(false)
    })
  })
}

const sendCredentials = async function(apg, csrfToken, username, password) {
  let opts = { headers: getHeaders(apg, jsonHeaders()) }
  let data = { csrfToken, username, password, appid: apg.appId() }
  let args = [`${apg.host()}/api/auth/callback/credentials`, data, opts]
  return new Promise((res, rej) => {
    send(apg, 'post', args, ans => {
      res(ans?.data)
    }, err => {
      console.warn(err)
      res(false)
    })
  })
}

const getSession = async function(apg) {
  let opts = { headers: getHeaders(apg, jsonHeaders()) }
  let args = [`${apg.host()}/api/auth/session`, opts]
  return new Promise((res, rej) => {
    send(apg, 'get', args, ans => {
      res(ans?.data)
    }, err => {
      console.warn(err)
      res(false)
    })
  })
}

const userLogout = async function(apg, path) {
  let csrf = await loadCsrf(apg)
  let data = { csrfToken: csrf }
  let opts = { headers: getHeaders(apg, jsonHeaders()) }
  let args = [`${apg.host()}/api/auth/signout`, data, opts]
  return new Promise((res, rej) => {
    apg.clearModels()
    send(apg, 'post', args, ans => {
      clearPages()
      navigate(path || '/')
      res(true)
    }, err => {
      console.warn(err)
      res(false)
    })
  })
}

const userLogin = async function(apg, email, password, cb) {
  let path = '/'
  let err = false
  let csrf = await loadCsrf(apg)
  let res = await sendCredentials(apg, csrf, email, password)
  if (res) {
    let user = await getSession(apg)
    if (user) {
      let them = user.user
      if (window.location.search) {
        path = window.location.search.startsWith('?p=') ? window.location.search.substring(3) : '/'
      } if (apg.li('(has-private-home)') && isRole(them.role, 'user')) {
        path = '/priv/home'
      } else if (apg.li('(has-admin-home)') && roleGte(them.role, "admin")) {
        path = '/admin/home'
      }
    } else {
      console.warn('could not get user')
      apg.error('There was a problem logging you in. Please try again later or contact support.')
      err = true
    }
  } else {
    console.warn('could not log in')
    apg.error('There was a problem logging you in. Please try again later or contact support.')
    err = true
  }
  if (!err) {
    clearPages()
    navigate(path)
  }
  if (cb) cb();
}

const send = function(apg, mtd, args, res, rej) {
  apg.loading()
  try {
    axios[mtd](...args)
      .then(ans => { apg.loaded(); res(ans) })
      .catch(err => { apg.loaded(); rej(err) })
  } catch (err) {
    apg.loaded()
    rej(err)
  }
}

const getHeaders = (apg, others) => {
  let h = {
    'x-api-key': `${apg.apiKey()}${apg.appKey()}`,
    'Accept': 'text/plain',
    'Content-Type': 'text/plain'
  }
  return others ? Object.assign(h, others) : h
}

const userSignUp = function(apg, email, password, path, cb) {
  let exp = `{ "email" "${email}" "password" "${password}" "appId" "${apg.appId()}" }`
  let l = `(fn* (input) (eval \`(~input ${exp} )))`
  let opts = { headers: getHeaders(apg) }
  let args = [`${apg.apiRoot()}/${apg.appId()}/func/app-user-signup.lisp`, l, opts]
  return new Promise((res, rej) => {
    send(apg, 'post', args, ans => {
      if (cb) cb();
      navigate(path)
      res(true)
    }, err => {
      apg.error('There was a problem signing you up. Please try again later or contact support.')
      res(false)
    })
  })
}

const paramsToMap = function(apg, name, params, cb) {
  return new Promise((res, rej) => {
    let p = new Map()
    p.set('email', apg.user()?.email || '')
    p.set('uid', apg.user()?.uid || '')
    res(cb(apg, name, p, true))
  })
}

const callServer = function(apg, name, params, loop = false) {
  // console.log('callServer', name, params)
  if (!isMap(params) && !loop) return paramsToMap(apg, name, params, callServer)
  let exp = `${PRINT(params)}`
  let l = `(fn* (input) (eval \`(~input ${exp} )))`
  let opts = { headers: getHeaders(apg) }
  let args = [`${apg.apiRoot()}/${apg.appId()}/func/${name}`, l, opts]
  return new Promise((res, rej) => {
    send(apg, 'post', args, ans => {
      res(ans)
    }, err => {
      apg.error('There was a problem calling the server. Please try again later or contact support.')
      res(false)
    })
  })
}

const userVerifyMobile = function(apg, id) {
  let exp = `{ "id" "${id}" "appId" "${apg.appId()}" }`
  let l = `(fn* (input) (eval \`(~input ${exp} )))`
  let opts = { headers: getHeaders(apg) }
  let args = [`${apg.apiRoot()}/${apg.appId()}/func/app-user-verify-mobile.lisp`, l, opts]
  return new Promise((res, rej) => {
    send(apg, 'post', args, ans => {
      let exp = ans?.data || false
      if (ans?.status === 200) {
        res(apg.li(exp))
      } else {
        apg.error(`Failed to verify Mobile. Reason '${exp}'`)
        res(false)
      }
      res(true)
    }, err => {
      apg.error('There was a problem verifying your mobile. Please contact support.')
      res(false)
    })
  })
}

const userVerifyEmail = function(apg, id) {
  let exp = `{ "id" "${id}" "appId" "${apg.appId()}" }`
  let l = `(fn* (input) (eval \`(~input ${exp} )))`
  let opts = { headers: getHeaders(apg) }
  let args = [`${apg.apiRoot()}/${apg.appId()}/func/app-user-verify-email.lisp`, l, opts]
  return new Promise((res, rej) => {
    send(apg, 'post', args, ans => {
      let exp = ans?.data || false
      if (ans?.status === 200) {
        res(apg.li(exp))
      } else {
        apg.error(`Failed to verify Email. Reason '${exp}'`)
        res(false)
      }
    }, err => {
      apg.error('There was a problem verifying your email. Please contact support.')
      res(false)
    })
  })
}
