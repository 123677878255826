
    <template>
      <h2 :id="i">
        <button type="button" v-bind="a.p(m(p.p))" :class="a.c(p.cl)" :data-accordion-target="dat">
          <slot name="prepend"></slot>
          <slot></slot>
          <div class="grow" />
          <template v-if="$slots.append">
            <slot name="append"></slot>
          </template>
          <template v-else>  
            <Icon :p="{ src: 'mdi-chevron-down' }"/>
          </template>
        </button>
      </h2>
    </template>
    <script setup>
    import { useAppeggio } from '../../useAppeggio.js'
    const a = useAppeggio()
    const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
    const i = p.p?.id !== undefined ? 'ac' + p.p.id : 'accordionHeader' + p.p.aid
    const m = v => Object.assign({}, v, { id: undefined })
    const dat = '#' + i + '-body'
    </script>
  