<template>
  <div v-bind="a.p(p.p)" :class="a.c(p.cl)" role="tooltip">
    <slot></slot>
    <div class="tooltip-arrow" data-popper-arrow></div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
onMounted(_ => a.fbInit('tooltips'))
</script>