<template>
  <component :is="p.p.type || 'p'" :aid="p.p.aid" :class="a.c(p.cl)" class="a-text">
    <slot name="prepend" />
    <slot v-if="$slots.default"></slot>
    <span v-else v-html="p.p.val"></span>
    <slot name="append" />
  </component>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
</script>