import { computed } from 'vue';


export function  useLinkable(props) {

  const currentTag = computed(() => {

    if (props.href) {
      return 'a';
    }

    return 'div';

  });

  return {
    currentTag,
  };

}
