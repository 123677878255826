<template>
  <input :id="i" type="text" v-model="m" v-bind="a.p(p.p)" :class="a.c(dcl(p.cl))" :disabled="dsbld" @keydown="keydown">
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number] } })
const i = p.p?.id ? p.p?.id : 'inputField' + p.p?.aid
const im = ref(p.modelValue === undefined ? '' : p.modelValue)
const m = computed({
  get: _ => p.modelValue === undefined ? im.value : p.modelValue,
  set: v => {
    if (p.modelValue === undefined) im.value = v
    emit('update:modelValue', v)
  }
})
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
const dcl = cl => isDsbld() ? cl + ' cursor-not-allowed opacity-50' : cl
onUpdated(_ => {
  dsbld.value = isDsbld()
})
const keydown = $e => {
  if ($e.key !== 'Escape') {
    $e.handled = true
  }
}
</script>